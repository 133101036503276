export class NameHelper {
  static getSocialNameOrName(
    patient,
    user,
  ) {
    const  getValues = (payload) => {
      const { fullName: full, firstName: first1, name: first2, lastName: last } = payload || {};
      return full || `${first1 || first2 || ''} ${last || ''}`.trim();
    }

    const socialNamePatient = patient?.diversity?.socialName || patient?.socialName || null;
    const socialNameUser = user?.diversity?.socialName || user?.socialName || null;

    if (socialNamePatient || socialNameUser) {
      return socialNamePatient || socialNameUser;
    }

    if (patient) {
      return getValues(patient);
    }

    if (user) {
      return getValues(user);
    }

    return null;
  }
}
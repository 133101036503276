import apiLogin from "../../services/apiLogin";
import { getCredentials } from "../../utils/credentials";

export const getPatientToken = async (patientId) => {
  return await apiLogin
    .get(`/mc/auth/token/${patientId}`, {
      headers: {
        Authorization: `Bearer ${getCredentials().patientToken}`
      }
    })
    .then(async ({ data }) => {
      const { patientToken } = data;
      return patientToken;
    })
    .catch(() => {
      return "";
    });
};

export function* getDocumentsPatient(id, document) {
  try {
    const response = yield apiPatient.post(`/uploads/documents`, {
      id,
      document,
    });
    if (response.data.patient.fileDocuments.length > 0) {
      return {
        documents: response.data.patient.fileDocuments,
        existsDocuments: true,
      };
    } else {
      return {
        documents: null,
        existsDocuments: false,
      };
    }
  } catch {
    return {
      documents: null,
      existsDocuments: false,
    };
  }
}

import { call, put, all, select } from "redux-saga/effects";
import { setLoading, verifyAuth, reSendEmail } from "../actions/utils.actions";
import {
  openModalUser,
  openModalVerification,
  openModalPin,
  setPhone,
  setCurrentPrivatePlan,
  setHomePage,
  userSetTelemedicina,
  userSetTelepsicologia,
} from "../actions/user.actions";

import { setSelectedPatient } from "../actions/patient.actions";
import { getAllDependents } from "../actions/depedents.actions";
import { show } from "../../services/alert";
import { goBack, push } from "../../services/navigate";
import { generateDate } from "../../utils/date";
import api from "../../services/api";
import apiPatient from "../../services/apiPatient";
import { videoCall } from "../../redux/actions/call.actions";
import { stripPrefix } from "../../utils/documentHelpers";
import { compressImage, isImageType } from "../../utils/compression";
import { setCredentials } from "../../utils/credentials";
import { LOGIN_ROUTE } from "../../routes/routes.contants";
import { getPatientToken } from "../../redux/sagas/patient";
import { getDocumentsPatient } from "./patient";
import apiPatientUpload from "../../services/apiPatientUpload";

export function* login({ payload }) {
  yield put(setLoading(true));

  try {
    const { username, password } = payload;

    const request = () => api.post("/login/v3", { username, password });
    const { data } = yield call(request);

    setCredentials(data);

    yield all([put(getAllDependents())]);
    yield (window.location.href = "/provedores");
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error === "UserNotConfirmedException"
    ) {
      return show(
        error.response.data.message,
        true,
        reSendEmail({ username: payload.username })
      );
    }
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* register({ payload }) {
  yield put(setLoading(true));

  const birthDate = generateDate(payload.birthDate);
  const newUser = {
    name: payload.name,
    lastName: payload.lastName,
    birthDate,
    documentType: payload.documentType,
    nationality: payload.nationality,
    document: payload.document,
    phone: payload.phone,
    email: payload.email,
    password: payload.password,
    sex: payload.sex,
    rg: payload.rg,
    userTerms: true,

    // dados de endereço
    address: payload.cep,
    numberAddress: payload.numberAddress,
    stateAddress: payload.stateAddress,
    cityAddress: payload.cityAddress,
    streetAddress: payload.address,
    complementAddress: payload.complementAddress,
  };

  try {
    const request = () => api.post("/user/create", newUser);
    const { data } = yield call(request);

    const buy = localStorage.getItem("buy-flag");

    if (buy && data) {
      localStorage.removeItem("buy-flag");

      const plan = localStorage.getItem("@current_plan");
      const selected = JSON.parse(plan);
      if (plan) {
        return push(`/comprar/${selected.namePlan}`);
      } else {
        return push("/comprar");
      }
    }

    show(
      "Usuário cadastrado com sucesso, verifique seu e-mail para confirmar sua conta"
    );
    return push(LOGIN_ROUTE);
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* editUser({ payload }) {
  yield put(setLoading(true));
  const birthDate = generateDate(payload.birthDate);

  const updateUser = {
    birthDate,
    documentType: payload.documentType,
    id_conecta: payload.id_conecta,
    lastName: payload.lastName,
    name: payload.name,
    phone: payload.phone,
    sex: payload.sex,
  };

  try {
    if (payload.image && typeof payload.image === "object") {
      const uploadPhoto = new FormData();
      uploadPhoto.append("file", payload.image);
      uploadPhoto.append("documentType", "PROFILE_PIC");

      const uploadFile = () => api.post("/upload/file", uploadPhoto);
      const uploadResponse = yield call(uploadFile);

      updateUser.image = stripPrefix(uploadResponse.data.location);
    }

    const uploadUser = () => api.put("/user/edit", updateUser);
    const updatePatient = () =>
      apiPatient.put("/users", {
        firstName: payload.name,
        lastName: payload.lastName,
        phone: payload.phone,
        birthDate,
        sex: payload.sex,
        document: payload.document,
      });

    yield call(updatePatient);
    const { data } = yield call(uploadUser);
    yield all([put(openModalUser(false)), put(verifyAuth())]);

    show(data.message);
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* sendLinkRecovery({ payload }) {
  yield put(setLoading(true));

  try {
    const { username } = payload;
    const request = () => api.post("/user/reset-password", { username });
    yield call(request);
    push("/home");
    show("Enviamos um link de redefinição de senha para o e-mail informado");
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* recoveryPassword({ payload }) {
  yield put(setLoading(true));

  try {
    const { username, password, codeConfirm } = payload;
    const request = () =>
      api.post("/user/confirm-recover-password", {
        username,
        password,
        codeConfirm,
      });
    const { data } = yield call(request);
    yield show(data.message);
    push(LOGIN_ROUTE);
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* changePassword({ payload }) {
  yield put(setLoading(true));

  const dataRequest = {
    newPassword: payload.newPassword,
    oldPassword: payload.oldPassword,
  };

  try {
    const request = () => apiPatient.put("/users/change-password", dataRequest);
    const { data } = yield call(request);
    if (data) {
      yield show("Senha alterada com sucesso");
      yield put(openModalUser(false));
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* patientHasDocuments() {
  const patient = yield select((state) => state.patientReducer.patient);
  const patientSelected = JSON.parse(
    localStorage.getItem("@conecta:patient-selected")
  );
  const titularId = yield select((state) => state.userReducer.user._id);
  const isDependent = patient._id !== titularId;
  const idPatient = isDependent
    ? patientSelected._id
    : patientSelected.idPatientConecta;

  const selectedPatientHasDocs = yield getDocumentsPatient(
    idPatient,
    patientSelected.document
  );

  if (selectedPatientHasDocs.existsDocuments) {
    return true;
  }

  return false;
}

function getNameProvider(name, selected_provider) {
  let nameProvider = selected_provider
    .toLowerCase()
    .includes(name.toLowerCase());
  if (nameProvider) {
    return name;
  }
}

export function* findPhoneNumber({ payload }) {
  yield put(setLoading(true));

  const providerSelect = payload.empresas[0].descricao;

  const { phoneNumber } = payload;
  const number = { number: phoneNumber };

  try {
    if (getNameProvider("Vivo", providerSelect)) {
      const request = () => api.post("/vivo/verify-pin", number);
      const { data } = yield call(request);

      if (data) {
        yield all([put(openModalVerification(false)), put(openModalPin(true))]);
        yield all([put(setPhone(phoneNumber))]);
      }
    }

    if (getNameProvider("Claro", providerSelect)) {
      const request = () => api.post("/claro/verify-pin", number);
      const { data } = yield call(request);

      if (data) {
        yield all([put(openModalVerification(false)), put(openModalPin(true))]);
        yield all([put(setPhone(phoneNumber))]);
      }
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* findPin({ payload }) {
  let provider = localStorage.getItem("@conecta:selected-provider");

  provider = JSON.parse(provider);

  const providerSelect = provider.descricao;

  yield put(setLoading(true));

  try {
    if (getNameProvider("Claro", providerSelect)) {
      const request = () => api.post("/claro/check-pin", payload);
      const { data } = yield call(request);

      if (data) {
        const selectedPatientHasDocs = yield patientHasDocuments();

        if (!selectedPatientHasDocs) {
          push("/documentos");
        }

        push("/sintomas");
        yield all([put(openModalPin(false))]);
        yield all([put(setSelectedPatient(true))]);
      }
    }
    if (getNameProvider("Vivo", providerSelect)) {
      const request = () => api.post("/vivo/check-pin", payload);
      const { data } = yield call(request);

      if (data) {
        const selectedPatientHasDocs = yield patientHasDocuments();

        if (!selectedPatientHasDocs) {
          push("/documentos");
        }

        push("/sintomas");
        yield all([put(openModalPin(false))]);
        yield all([put(setSelectedPatient(true))]);
      }
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* sendUpdateProfilePicture({ payload }) {
  yield put(setLoading(true));
  const patientSelected = JSON.parse(
    localStorage.getItem("@conecta:patient-selected")
  );
  const patientToken = yield getPatientToken(patientSelected._id);

  async function createFileFromBlobUrl(url, name, type) {
    if (typeof window === "undefined") return;
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type,
    };
    return new File([data], url, metadata);
  }

  async function createFilesArray() {
    const fileBuffers = await Promise.all(
      payload.map((elem) => {
        const docMime = elem.documentImage?.type;
        const docFile = elem.documentImage?.url;
        return createFileFromBlobUrl(
          docFile,
          `${elem.documentType}.${docMime?.split("/")[1]}`,
          "image/*"
        );
      })
    );
    return fileBuffers;
  }

  async function compressImages(files) {
    const fileBuffers = await Promise.all(
      files.map(async (elem) => await compressImage(elem))
    );
    return fileBuffers;
  }

  const rawFileObjects = yield call(createFilesArray);
  const fileObjects = yield call(compressImages, rawFileObjects);
  const formData = new FormData();

  fileObjects.forEach((elem, idx) => {
    elem.documentName = payload[idx].documentName;
    formData.append("files", elem, payload[idx].documentName);
  });

  try {
    const request = () =>
      apiPatientUpload.put("/uploads/image-profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "patient-token-temporary": patientToken,
        },
      });
    const { data: response } = yield call(request);
    if (response.profilePicture.length > 0) {
      show(response.message);
      goBack();
    } else {
      show("Não foi possível salvar seus arquivos. Tente novamente!");
    }
    yield put(setLoading(false));
  } catch (error) {
    const { data } = error.response;
    yield put(setLoading(false));
    show(data.message);
  } finally {
    yield put(verifyAuth());
  }
}

export function* sendUploadDocuments() {
  yield put(setLoading(true));
  const patientSelected = JSON.parse(
    localStorage.getItem("@conecta:patient-selected")
  );
  const patientToken = yield getPatientToken(patientSelected._id);
  const titularId = yield select((state) => state.userReducer.user._id);
  const isDependent = patientSelected._id !== titularId;
  const docs = JSON.parse(localStorage.getItem("conecta:docs"));
  const { files, selectedDocumentType } = yield select(
    (state) => state.utilsReducer.documentsUpload
  );

  async function createFileFromBlobUrl(url, name, type) {
    if (typeof window === "undefined") return;
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type,
    };
    return new File([data], url, metadata);
  }

  async function createFilesArray() {
    const fileBuffers = await Promise.all(
      docs.map((elem) => {
        const docMime = elem.documentImage?.type;
        const docFile = elem.documentImage?.url;
        return createFileFromBlobUrl(
          docFile,
          `${elem.documentType}.${docMime?.split("/")[1]}`,
          "image/*"
        );
      })
    );
    return fileBuffers;
  }

  async function compressImages(files) {
    const fileBuffers = await Promise.all(
      files.map(async (elem) => await compressImage(elem))
    );
    return fileBuffers;
  }

  const rawFileObjects = yield call(createFilesArray);
  const fileObjects = yield call(compressImages, rawFileObjects);
  const formData = new FormData();

  fileObjects.forEach((elem, idx) => {
    elem.originalName = files[idx].originalName;
    formData.append("files", elem, files[idx].originalName);
  });
  formData.set("selectedDocument", selectedDocumentType);
  try {
    const request = () =>
      apiPatientUpload.post("/uploads/signups/documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "patient-token-temporary": patientToken,
        },
      });
    const { data: response } = yield call(request);
    if (response.signUpDocuments.length > 0) {
      localStorage.removeItem("conecta:docs");
      show(response.message);
      push("/sintomas");
    } else {
      show("Não foi possível salvar seus arquivos. Tente novamente!");
    }
  } catch (error) {
    const { data } = error.response;
    show(data.message);
  } finally {
    yield all([put(setLoading(false)), put(verifyAuth())]);
  }
}

export function* sendUploadExams() {
  yield put(setLoading(true));
  const patientSelected = JSON.parse(
    localStorage.getItem("@conecta:patient-selected")
  );
  const patientToken = yield getPatientToken(patientSelected._id);
  const titularId = yield select((state) => state.userReducer.user._id);
  const isDependent = patientSelected._id !== titularId;
  const docs = JSON.parse(localStorage.getItem("conecta:docs"));
  const { files, selectedDocumentType } = yield select(
    (state) => state.utilsReducer.documentsUpload
  );

  async function createFileFromBlobUrl(url, name, type) {
    if (typeof window === "undefined") return;
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type,
    };
    return new File([data], url, metadata);
  }

  async function createFilesArray() {
    const fileBuffers = await Promise.all(
      docs.map((elem) => {
        const docMime = elem.documentImage?.type;
        const docFile = elem.documentImage?.url;
        return createFileFromBlobUrl(
          docFile,
          `${elem.documentType}.${docMime?.split("/")[1]}`,
          "image/*"
        );
      })
    );
    return fileBuffers;
  }

  async function compressImages(files) {
    const fileBuffers = await Promise.all(
      files.map(async (elem) => await compressImage(elem))
    );
    return fileBuffers;
  }

  const rawFileObjects = yield call(createFilesArray);
  const fileObjects = yield call(compressImages, rawFileObjects);
  const formData = new FormData();

  fileObjects.forEach((elem, idx) => {
    elem.originalName = files[idx].originalName;
    formData.append("files", elem, files[idx].originalName);
  });

  formData.set("documents", "EXAM");

  try {
    yield put(setLoading(true));
    const request = () =>
      apiPatientUpload.post("/uploads/multiple", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "patient-token-temporary": patientToken,
        },
      });
    const response = yield call(request);
    if (response.data?.images?.length > 0) {
      let formmattedExamsDocuments = [];
      response.data.images.map((image) => {
        const formattedImage = {
          bucket: image.Bucket,
          eTag: image.ETag,
          key: image.key,
          Key: image.Key,
          location: image.Location,
          serverSideEncryption: image.ServerSideEncryption,
          versionId: image.VersionId,
        };
        formmattedExamsDocuments.push(formattedImage);
      });
      localStorage.setItem(
        "conecta:exams",
        JSON.stringify(formmattedExamsDocuments)
      );
      goBack();
      show("Os arquivos foram enviados com sucesso");
    } else {
      localStorage.setItem("conecta:exams", JSON.stringify([]));
      show("Não foi possível salvar seus arquivos. Tente novamente!");
    }
  } catch (error) {
    const { data } = error.response;
    show(data.message);
  } finally {
    yield all([put(setLoading(false)), put(verifyAuth())]);
  }
}

export function* sendUploadFiles({ payload }) {
  const getReducer = (state) => {
    return {
      patient: state.patientReducer.patient,
      disease: state.utilsReducer.currentDisease,
    };
  };

  async function compressImages(files) {
    const fileBuffers = await Promise.all(
      files.map(async (elem) => {
        console.log("ELEMENT:::", elem);
        if (isImageType(elem.type)) {
          const compressed = await compressImage(elem);
          // compressed.name = elem.name.toLowerCase()
          return compressed;
        }

        return new Promise((resolve, _reject) => resolve(elem));
      })
    );
    return fileBuffers;
  }

  const rawFiles = payload.getAll("files");
  const files = yield call(compressImages, rawFiles);

  payload.delete("files");
  files.forEach((file) => payload.append("files", file, file.name));

  yield put(setLoading(true));

  try {
    const request = () => api.post("/upload/files", payload);
    const response = yield call(request);

    const docsReferences = response.data;

    if (response && response.message) {
      show(response.message);
    } else {
      const dataReducer = yield select(getReducer);
      yield all([put(videoCall({ docsReferences, dataReducer }))]);
    }
  } catch (error) {
    yield put(setLoading(false));
  }
}

export function* patientSelected({ payload }) {
  const patientSelected = JSON.parse(
    localStorage.getItem("@conecta:patient-selected")
  );
  const titularId = yield select((state) => state.userReducer.user._id);
  const isDependent = patientSelected._id !== titularId;
  yield put(setLoading(true));

  const selectedPatient = payload;

  const idPatient = isDependent
    ? patientSelected._id
    : patientSelected.idPatientConecta;
  const selectedPatientHasDocs = yield getDocumentsPatient(
    idPatient,
    patientSelected?.document
  );

  if (!selectedPatient) {
    yield put(setLoading(false));
    return;
  }

  if (selectedPatientHasDocs.existsDocuments) {
    yield put(setLoading(false));
    return;
  }

  yield put(setLoading(false));
}

export function* currentPrivatePlanRequested({ payload }) {
  const idConvenio = payload;
  yield put(setLoading(true));

  try {
    const request = () =>
      api.post(`/plans/getCurrentPlan/`, { idConvenio: idConvenio });
    const { data } = yield call(request);

    yield put(setCurrentPrivatePlan(data));
  } catch (error) {}
}

export function* setProvider() {
  yield all([
    put(setHomePage("/home")),
    put(userSetTelemedicina(true)),
    put(userSetTelepsicologia(false)),
  ]);
}

import { all, call, put, select, take } from "redux-saga/effects";
import { jwtDecode } from 'jwt-decode';
import { setLoading } from "../actions/utils.actions";
import {
  setDependents,
} from "../actions/depedents.actions";
import { show } from "../../services/alert";
import api from "../../services/api";
import apiLogin from "../../services/apiLogin";
import { apiMinhaConta } from "../../services/apiMinhaConta";
import { managementDependentsToExpectedFormat } from "../../utils/managementDependentsToExpectedFormat";
import { stripPrefix } from "../../utils/documentHelpers";
import { getCredentials } from "../../utils/credentials";

function sortAscendingList(a, b) {
  if (a.createdAt && b.createdAt) {
    return a.createdAt > b.createdAt;
  } else {
    return false;
  }
}

export function* getAll() {
  yield put(setLoading(true));
  try {

    const { data: dependent } = yield apiMinhaConta.get('/patient-portal/v1/dependent')
    const data = {}

    data.dependents = dependent.map(managementDependentsToExpectedFormat)

    let formattedDependents = [];

    const dependents = data?.dependents.length > 1 ? data?.dependents.filter((dependent) => {
      const currentDate = new Date();
      const patientBirthDate = new Date(dependent?.birthDate);

      const ageInMillis = currentDate.getTime() - patientBirthDate.getTime();

      const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
      if (!dependent?.isHolder && !dependent?.hasSpecialCondition && ageInYears >= 18) {
        return false;
      }
      return true;
    }) : data?.dependents;

    yield Promise.all(
      dependents.map(async (dependent) => {
        formattedDependents.push({
          id: dependent._id,
          document: dependent.document,
        });
      })
    );

    yield put(setDependents(dependents));
  } catch (error) {
    console.log({ error })
    show(error?.response?.data?.message || 'Algo deu errado, tente novamente');
  } finally {
    yield put(setLoading(false));
  }
}

export function* getDocumentsDependent(documentNumber) {
  try {
    const response = yield api.get(`/dependent/documents/${documentNumber}`);
    if (response.data.signUpDocuments.length > 0) {
      return {
        documents: response,
        existsDocuments: true,
      };
    } else {
      return {
        documents: response,
        existsDocuments: false,
      };
    }
  } catch {
    return {
      documents: null,
      existsDocuments: false,
    };
  }
}

export const getDependentEligibility = async (patientDependentId) => {
  const patientToken = await apiLogin
    .get(`/mc/auth/token/${patientDependentId}`, {
      headers: {
        Authorization: `Bearer ${getCredentials().patientToken}`
      }
    })
    .then(async ({ data }) => {
      const { patientToken } = data;
      return patientToken;
    })
    .catch(() => {
      return "";
    });

  if (!patientToken) {
    return { hasEligibility: false, message: 'Não foi possível validar a elegibilidade do paciente' };
  }

  return { hasEligibility: true, message: 'Paciente elegível' };
};

import React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { format } from "date-fns";
import * as S from "../cards-terms/styles";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";
import { registerViewTerms } from "../../redux/actions/termsProvider.actions";

export default function ContentTerms({ term }) {
  const dispatch = useDispatch();
  const markdown = getRemoteConfigValue(term?.type);
  const patientTerms = useSelector((state) => state.termsProvider.patientTerms);
  const user = useSelector((state) => state.userReducer.user);
  const hasHistory = !!(
    patientTerms?.termsHistory && patientTerms?.termsHistory.length > 0
  );

  const history = hasHistory
    ? patientTerms?.termsHistory.find((elem) => elem.name === term?.type)
    : { lastViewDate: null };
  const lastView = history?.lastViewDate
    ? new Date(history?.lastViewDate)
    : new Date();

  const visualizationDate = format(lastView, `dd/MM/yyyy 'às' HH'h'mm`);

  const registerTerms = React.useCallback(() => {
    if (term?.type) {
      dispatch(registerViewTerms(term?.type));
    }
  }, [dispatch]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    registerTerms();
  }, []);

  const getUserName = () => {
    if (user.diversity?.socialName) {
      return user.diversity.socialName;
    }

    return `${user.name} ${user.lastName}`;
  };

  return (
    <>
      <S.Header>
        <h1>{term?.title}</h1>
      </S.Header>

      <S.ContainerContent className="read-term">
        <S.ContainerTerm>
          <S.Text>
            <strong style={{ fontSize: 20 }}>{term?.subtitle}</strong>
          </S.Text>
          <ReactMarkdown>{markdown}</ReactMarkdown>
        </S.ContainerTerm>

        <S.CardUserInfo>
          <S.UserData>
            <span>Titular da conta</span>
            <h3>{getUserName()}</h3>
          </S.UserData>

          <S.UserTimeViewed>
            Visualizado em: <span>{visualizationDate}</span>
          </S.UserTimeViewed>
        </S.CardUserInfo>
      </S.ContainerContent>
    </>
  );
}

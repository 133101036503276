export function managementDependentsToExpectedFormat(data) {
  return {
    sex: data.biologicalSex === "FEMALE" ? "F" : "M",
    birthDate: data.birthDate,
    document: data?.cpf || data?.foreignDocument,
    documentType: data.cpf ? "CPF" : "PASSPORT",
    createdAt: data.createdAt,
    _id: data.id,
    isHolder: data.isHolder,
    name: data.name,
    photoProfile: null,
    phone: data.phone || null,
    products: data.products || [],
    telecom: data.telecom || [],
    updatedAt: data.updatedAt || null,
    diversity: data.diversity,
  };
}

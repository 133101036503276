import axios from "axios";

const URL = process.env.REACT_APP_LOGIN_URL;

const api = axios.create({
  baseURL: `${URL}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    Accept: "application/json, text/plain, /",
    "Content-Type": "application/json;charset=utf-8",
  },
});

export default api;

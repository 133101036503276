import React, { useEffect, useState } from "react";
import moment from "moment";
import App from "../../container";
import { TERMS_PROVIDER_STATUS } from "../home/select-patient/terms-provider/constants";
import {
  Sections,
  BackButtonContainer,
  IconBack,
  Container,
  Columns,
  CardShareChoice,
  ToggleSwitch,
  CardShareChoiceColumns,
  CardShareChoiceRows,
  TermsHistoryRow,
  TermsHistoryColumn,
  TermsHistoryRowHeader,
  WaitSpan,
  PaginationGroup,
  WaitOpacity,
} from "./styles";
import Pagination from 'rc-pagination';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setTermsProvider, setTermsProviderStatusAndTime } from "../../redux/actions/termsProvider.actions";
import { push } from "../../services/navigate";

export default function TermsProviderPage() {
  const dispatch = useDispatch();
  const hasTermsProvider = useSelector(
    (state) => state.termsProvider.status
  );
  const hasTermsProviderFeatureEnabled = useSelector(
    (state) => state.featureFlagsReducer.featureFlags.termsProviderEnabled
  );

  useEffect(() => {
      if (!hasTermsProvider || !hasTermsProviderFeatureEnabled || hasTermsProvider === TERMS_PROVIDER_STATUS.NOT_ELEGIBLE) return push("/home");
  }, [dispatch, push]);

  return (
    <App>
      <Sections>
        <BackButton />
        <WelcomeProvider />
        <ShareEspecificData />
        <RememberYourChoice />
        <HistoryChoices />
      </Sections>
    </App>
  );
}

export function WelcomeProvider() {
  const termsProviderName = useSelector((state) => state.termsProvider.provider);
  return (
    <Container>
      <h1>Privacidade {termsProviderName}</h1>
      <p>
        Aceitando o termo de privacidade, alguns dados do prontuário do paciente, 
        com aceite de compartilhamento permanecem acessíveis para a empresa
        {' '}
        <strong>{termsProviderName}</strong>.
      </p>
    </Container>
  );
}

export function ShareEspecificData() {
  return (
    <Container>
      <h2>Quais dados de saúde ficarão acessíveis a empresa</h2>
      <p>
        <Columns>
          <ul>
            <li>CPF do paciente</li>
            <li>Data do atendimento</li>
            <li>Peso</li>
            <li>Altura</li>
            <li>IMC</li>
          </ul>
          <ul className="divider-line">
            <li>Consultas</li>
            <li>Resultados de Exames</li>
            <li>Pressão arterial</li>
            <li>CID dos médicos que prestam serviço</li>
            <li>Questionário de saúde (auto-referido pelo paciente)</li>
          </ul>
        </Columns>
      </p>
    </Container>
  );
}

export function RememberYourChoice() {
  const termsProviderUrl = useSelector((state) => state.termsProvider.termUrl);
  return (
    <Container>
      <p>
        <strong>Lembre-se:</strong> é possível desabilitar o compartilhamento previamente. 
        Porém, ao desabilitar o compartilhamento, os dados ficarão inacessíveis a empresa, no 
        entanto não serão excluídos da base de dados do Hospital Israelita Albert Einstein.
        {" "}
        <a href={termsProviderUrl} target="_blank">
          Termos de compartilhamento.
        </a>
      </p>
      <ShareChoiceComponent />
    </Container>
  );
}

export function BackButton() {
  const history = useHistory();
  function goBack() {
    history.goBack()
  }
  return (
    <BackButtonContainer onClick={goBack}>
      <IconBack />
      Voltar
    </BackButtonContainer>
  );
}

export function ShareChoiceComponent() {
  const dispatch = useDispatch();
  const termsProviderStatus = useSelector((state) => state.termsProvider.status);
  const termsProviderTime = useSelector((state) => state.termsProvider.lastAccepted);
  const [wait, setWait] = useState(false);
  
  async function onChangeChoice(event) {
    if (wait) {
      event.preventDefault();
      event.stopPropagation();
      return
    };
    setWait(true);
    const statusString = termsProviderStatus === TERMS_PROVIDER_STATUS.ACCEPTED ? TERMS_PROVIDER_STATUS.REJECTED : TERMS_PROVIDER_STATUS.ACCEPTED;
    dispatch(setTermsProviderStatusAndTime({
      status: statusString,
      lastAccepted: new Date().toISOString(),
    }));
    setTimeout(() => setWait(false), 3000);
  }

  return (
    <CardShareChoice>
      <CardShareChoiceColumns>
        <CardShareChoiceRows>
          <h3>Compartilhamento de dados</h3>
          <p>Eu permito compartilhar meus dados pessoais e de saúde</p>
          <small>Data da última alteração do aceite: {termsProviderTime && moment(termsProviderTime).format('DD/MM/YYYY HH:mm:ss')}</small>
        </CardShareChoiceRows>
          <ToggleSwitch wait={wait}>
            <input
              disabled={wait}
              checked={termsProviderStatus === TERMS_PROVIDER_STATUS.ACCEPTED}
              type="checkbox"
              id="terms_provider_choice"
              value={termsProviderStatus}
              onClick={onChangeChoice}
            />
            <label for="terms_provider_choice">
              {termsProviderStatus}
            </label>
            <WaitSpan>
              {wait ? 'Aguarde...' : null}
            </WaitSpan>
        </ToggleSwitch>
      </CardShareChoiceColumns>
    </CardShareChoice>
  );
}

export function HistoryChoices() {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [wait, setWait] = useState(false);
  const termsProviderMeta = useSelector((state) => state.termsProvider.meta);
  
  const statusTranslation = status => {
    switch(status) {
      case TERMS_PROVIDER_STATUS.ACCEPTED:  return 'Aceito';
      case TERMS_PROVIDER_STATUS.REJECTED:  return 'Não aceito';
      case TERMS_PROVIDER_STATUS.INITIAL:   return 'Sem ação';
      default: return 'Indefinido';
    }
  };


  function updateValue() {
    if (termsProviderMeta) {
      const { data, page, totalPages } = termsProviderMeta || {};
      data && setDataList(data || []);
      page && setCurrentPage(page || 0);
      totalPages && setTotalPages(totalPages || 0);  
    }
  }

  async function onChangePage(numberPage) {
    setWait(true);
    setCurrentPage(numberPage);
    setWait(false);
  }

  useEffect(updateValue, [termsProviderMeta]);
  // useEffect(() => (async() => await updateValuesFromApi())(), []);
  return (
    <Container>
      <WaitOpacity wait={wait}>
        <h2>Histórico de aceites</h2>
        <p>
          <TermsHistoryRowHeader>
            <TermsHistoryColumn>Data do atendimento</TermsHistoryColumn>
            <TermsHistoryColumn>Hora</TermsHistoryColumn>
            <TermsHistoryColumn width={150}>Aceite do Termo</TermsHistoryColumn>
          </TermsHistoryRowHeader>
          {dataList.map((el, index) => (
            <TermsHistoryRow key={index}>
              <TermsHistoryColumn>{moment(el.lastAccepted).format('DD/MM/YYYY')}</TermsHistoryColumn>
              <TermsHistoryColumn>{moment(el.lastAccepted).format('HH:mm')}</TermsHistoryColumn>
              <TermsHistoryColumn width={150}>{statusTranslation(el.status)}</TermsHistoryColumn>
            </TermsHistoryRow>
          ))}
        </p>
        <PaginationGroup>
          <Pagination
            className="ant-pagination"
            defaultCurrent={1}
            defaultPageSize={10}
            pageSize={10}
            onChange={onChangePage}
            total={totalPages}
            current={currentPage}
          />
        </PaginationGroup>
      </WaitOpacity>
    </Container>
  );
}

export function checkAuthFromPerson(personData) {
  const jsonData = {
    _id: personData._id,
    email: personData.email,
    birthDate: personData.birthDate?.replace(/-/g, "/") || "",
    document: personData.cpf || personData.foreignDocument,
    documentType: personData.cpf ? "CPF" : "PASSAPORTE",
    lastName: personData.name.split(" ").slice(-1).join("") || "",
    name: personData.name.split(" ").slice(0, -1).join(" ") || "",
    phone: personData.phone.split(" ")[1] || "",
    sex:
      personData.biologicalSex === "MALE"
        ? "M"
        : personData["biologicalSex"] === "FEMALE"
        ? "F"
        : "I",
    image: personData.imageProfileUrl,
    diversity: personData.diversity,
  };

  return jsonData;
}

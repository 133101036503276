import React from "react";

import {
  Button,
  Card,
  DivButtons,
  DivLabels,
  DivScheduled,
  GridScheduled,
  Icon,
  IconImg,
  Img,
  Infos,
  LabelSchedule,
  SubLabelSchedule,
  TitleSchedule
} from "./styles";

import { push } from "../../services/navigate";

function ConfirmInfos() {
  function goToHomePsico() {
    push("/telepsicologia");
  }
  function goToAppointments() {
    push("/consultas-agendadas");
  }

  return (
    <div>
      <Card>
        <Infos>
          <TitleSchedule title>Agendamento efetuado com sucesso!</TitleSchedule>
          <Icon>
            <Img src="img/verification.png" />
          </Icon>
          <TitleSchedule subtitle>
            Em caso de dúvidas, entre em contato:
          </TitleSchedule>

          <DivScheduled>
            <GridScheduled>
              <IconImg src="img/icon@.png" />
              <DivLabels>
                <LabelSchedule>Por e-mail</LabelSchedule>
                <SubLabelSchedule>canaisdigitais@einstein.br</SubLabelSchedule>
              </DivLabels>
            </GridScheduled>

            <GridScheduled>
              <IconImg src="img/phone.png" />
              <DivLabels>
                <LabelSchedule>Por telefone</LabelSchedule>
                <SubLabelSchedule>(11) 2151-7690</SubLabelSchedule>
              </DivLabels>
            </GridScheduled>
          </DivScheduled>
        </Infos>

        <DivButtons>
          <Button gray onClick={() => goToHomePsico()}>
            Home Page
          </Button>
          <Button blue onClick={() => goToAppointments()}>
            Ver agendamentos
          </Button>
        </DivButtons>
      </Card>
    </div>
  );
}
export default ConfirmInfos;

import { useState } from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {
  Container,
  ListPatient,
  PatientSection,
  ImagePatient,
  NamePatient,
  ButtonBack,
  LabelBack,
  IconBack,
  DivType,
  Icon,
  DivArrow,
  Division,
  Title,
  SubTitle,
  ContainerPatient,
} from "./styles";

import { GiBrain } from "react-icons/gi";

import { FiVideo } from "react-icons/fi";

import { useSelector, useDispatch } from "react-redux";
import Modal from "../modal";
import {
  setPatient,
  setSelectedPatient,
} from "../../redux/actions/patient.actions";

import { setOldSlot } from "../../redux/actions/schedule.actions";

import { setScheduleType } from "../../redux/actions/schedule.actions";
import { setSelectedProgram } from "../../redux/actions/telepsico.actions";

import { push } from "../../services/navigate";
import { NameHelper } from "../../utils/nameHelper";

export default function App(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer.user);
  const scheduleType = useSelector((state) => state.schedule.scheduleType);
  const [hiddenArrow, setHiddenArrow] = useState(true);

  const dependentsProps = useSelector(
    (state) => state.dependentsReducer.dependents
  );

  let provider = localStorage.getItem("@conecta:selected-provider");

  provider = JSON.parse(provider);

  function _setScheduleType(type) {
    dispatch(setScheduleType(type));
  }

  function setDependentNameAndLastName(dependent) {
    const lastNameIndex = dependent.name.indexOf(" ");
    dependent.lastName = dependent.name.substr(
      lastNameIndex + 1,
      dependent.name.length
    );
    dependent.name = dependent.name.substr(0, lastNameIndex);

    return dependent;
  }
  function _setPatient(patient) {
    if (!patient.lastName) setDependentNameAndLastName(patient);
    dispatch(setPatient(patient));
    dispatch(setSelectedPatient(true));
    dispatch(setSelectedProgram(false));
    props.onClose();
    if (scheduleType == "TELEMEDICINA") {
      // dispatch(slotByPatient(user));
    }
    dispatch(setOldSlot(false));
    return push("/agendamento");
  }

  function getScrollPosition(el) {
    setHiddenArrow(false);
    const elemento = el.target;
    if (elemento.scrollHeight - elemento.scrollTop === elemento.clientHeight) {
      setHiddenArrow(true);
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      {scheduleType ? (
        <ContainerPatient>
          <ButtonBack type="button" onClick={() => props.onClose()}>
            <IconBack src="img/icons/back-icon.png"></IconBack>
            <LabelBack>Voltar</LabelBack>
          </ButtonBack>
          <h3>Selecione para quem é o atendimento</h3>
          <ListPatient onScroll={getScrollPosition}>
            <PatientSection onClick={() => _setPatient(user)}>
              <ImagePatient src={user?.image || "img/default.png"} />
              <NamePatient>
                {NameHelper.getSocialNameOrName(user)}
              </NamePatient>
            </PatientSection>
            {dependentsProps && dependentsProps.length > 0
              ? dependentsProps.map((patientItem, index) => {
                  if (
                    patientItem.document !==
                    user.document.replace(/([^0-9a-zA-Z])/gi, "")
                  )
                    return (
                      <PatientSection
                        key={index}
                        onClick={() => {
                          _setPatient(patientItem);
                        }}
                      >
                        <ImagePatient
                          src={patientItem.image || "img/default.png"}
                          onError={(e) => (e.target.src = "img/default.png")}
                        />
                        <NamePatient>
                          {patientItem.name || "Dependente"}
                        </NamePatient>
                      </PatientSection>
                    );
                })
              : null}
          </ListPatient>
          {!hiddenArrow && (
            <DivArrow>
              <ArrowDownwardIcon />
            </DivArrow>
          )}
        </ContainerPatient>
      ) : (
        <Container>
          <ButtonBack type="button" onClick={() => props.onClose()}>
            <IconBack src="img/icons/back-icon.png"></IconBack>
            <LabelBack>Voltar</LabelBack>
          </ButtonBack>
          <Title>Selecione a especialidade</Title>
          <DivType onClick={() => _setScheduleType("TELEMEDICINA")}>
            <Icon>
              <FiVideo />
            </Icon>
            <SubTitle>Agendamento Telemedicina</SubTitle>
          </DivType>
          <Division />
          <DivType onClick={() => _setScheduleType("TELEPSICOLOGIA")}>
            <Icon>
              <GiBrain />
            </Icon>
            <SubTitle>Agendamento Telepsicologia</SubTitle>
          </DivType>
        </Container>
      )}
    </Modal>
  );
}

import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { DateInput } from "semantic-ui-calendar-react";
import "semantic-ui-react";
import { Select } from "semantic-ui-react";
import {
  appointmentOld,
  cancelSchedulingSuccess,
  cancelSchedulingTruclinic,
  checkPatient,
  checkPatientTruclinic,
  clearSchedule,
  clearSuccessScheduled,
  doctorsBySpecialtyId,
  findProducts,
  slotByMonth,
  specialtiesByMonth,
} from "../../../redux/actions/schedule.actions";
import { show } from "../../../services/alert";

import {
  AvailableHours,
  BodySchedule,
  BodyScheduled,
  ButtonAdvance,
  ButtonOk,
  ChoicePatient,
  CircleSchedule,
  DateInputDiv,
  DivFilters,
  DivLabels,
  DivScheduled,
  DivSelect,
  Grid,
  GridContainer,
  GridFilterDoctor,
  GridFilterSpecialty,
  GridScheduled,
  Header,
  HeaderScheduled,
  HourButton,
  Icon,
  ImagePatientSelected,
  Img,
  InfoPatientSelected,
  LabelSchedule,
  LabelSelect,
  NamePatientSelected,
  NivelPatientSelected,
  PatientSelected,
  ScheduleDay,
  ScheduleGrid,
  ScheduleHour,
  SelectSpecialtyDoctor,
  SubLabelSchedule,
  SubTitleScheduled,
  TitleMessage,
  TitleScheduled,
} from "../styles";

import "../styles.css";

import { useDispatch, useSelector } from "react-redux";
import api from "../../../services/api";
import apiAps from "../../../services/apiAps";
import { push } from "../../../services/navigate";

import Patient from "../../../components/modal-schedule-type";

import { getRemoteConfigValue } from "../../../hooks/RemoteConfig";
import { setSelectedPatient } from "../../../redux/actions/patient.actions";
import { openModalPatient } from "../../../redux/actions/user.actions";
import { setLoading } from "../../../redux/actions/utils.actions";
import Confirm from "../modal-confirm";
import { NameHelper } from "../../../utils/nameHelper";

function Telemedicina() {
  const dispatch = useDispatch();
  const serviceVonageEnabled = getRemoteConfigValue("service_vonage_enabled");
  // provider selected
  let provider = localStorage.getItem("@conecta:selected-provider");
  provider = JSON.parse(provider);

  const [date, setDate] = useState("");
  const [keyHourSelected, setKeyHourSelected] = useState("");
  const [checked, setChecked] = useState(false);
  const specialty = useSelector((state) => state.schedule.specialty);
  const doctorsProps = useSelector((state) => state.schedule.doctors);
  const schedule = useSelector((state) => state.schedule.scheduleResult);
  const notFoundSlots = useSelector((state) => state.schedule.notFoundSlots);
  const successSlots = useSelector((state) => state.schedule.successSlots);
  const product = useSelector((state) => state.schedule.product);
  const successScheduled = useSelector(
    (state) => state.schedule.successScheduled
  );
  const appointments = useSelector((state) => state.schedule.scheduled);
  const appointmentsOld = useSelector((state) => state.schedule.appointmentOld);
  const modalPatient = useSelector((state) => state.userReducer.modalPatient);
  const cancelSchedule = useSelector((state) => state.schedule.cancelSchedule);

  const user = useSelector((state) => state.userReducer.user);
  const patient = useSelector((state) => state.patientReducer.patient);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [doctorId, setDoctor] = useState(false);
  const [specialtyId, setSpecialty] = useState(false);
  const [touched, setTouched] = useState(false);
  const [slot, setSelectedSlot] = useState(null);
  const [flagScheduling, setFlagScheduling] = useState(false);

  const TERMS_STATUS = {
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    INITIAL: "INITIAL",
  };

  const scheduleInitial = {
    date: moment().format("YYYY-MM-DD").toString(),
    user: user,
  };

  const hourFormat = (schedule || [])
    .filter((data) => moment(data.startAt).isSameOrAfter(moment()))
    .map((data) => ({
      ...data,
      startAt: moment(data.startAt).format("HH:mm"),
    }));

  function setModalPatient(bool) {
    dispatch(openModalPatient(bool));
  }

  async function getFlagScheduling() {
    return await api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
      return flag;
    });
  }

  useEffect(() => {
    getFlagScheduling().then((flag) => {
      if (
        !flag ||
        (user &&
          !user.checkElegibility &&
          provider.validar_tipo_elegibilidade != 0) ||
        (user &&
          user.checkElegibility &&
          provider.validar_tipo_elegibilidade != 0)
      ) {
        return push("/home");
      }
      dispatch(clearSuccessScheduled());
      dispatch(findProducts());
      // setModalPatient(true);
      dispatch(specialtiesByMonth());
      dispatch(clearSchedule());
      // dispatch(slotByPatient(user));
      dispatch(slotByMonth(scheduleInitial));
    });
  }, []);

  function handleChange(event, { value: date }) {
    setDate(date);
    setTouched(true);
    date = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD").toString();
    const params = {
      date: date,
      doctorId: doctorId,
      specialtyId: specialtyId,
      user: user,
    };
    dispatch(slotByMonth(params));
  }

  function changeSpecialty(_, data) {
    setSpecialty(data.value);
    dispatch(doctorsBySpecialtyId(data.value));
    setDate("");
    dispatch(clearSchedule());
  }
  function changeDoctor(_, data) {
    setDoctor(data.value);
    const doctor = data.value;
  }

  function selectHour(value) {
    setKeyHourSelected(value);
  }

  function confirmScheduling(slot) {
    patient.empresas = [provider];

    let userSelected = patient;
    if (patient && patient.holderId) {
      // userSelected.fullName = patient.diversity?.socialName ?? `${patient.firstName} ${patient.lastName}`;
      userSelected.fullName = `${patient.firstName} ${patient.lastName}`;
      userSelected.idVida = patient.idVida;
    } else {
      // userSelected.fullName = patient.diversity?.socialName ?? `${patient.name} ${patient.lastName}`;
      userSelected.fullName = `${patient.name} ${patient.lastName}`;
    }

    const nameCurrent = userSelected.fullName;
    const documentCurrent = userSelected.document;
    const birthDateCurrent = userSelected.birthDate.split("T")[0];

    const slotId = slot.id;
    const slotSpecialtyCurrent = slot.Specialty.id;

    if (appointments.length > 0) {
      let verifySlot = appointments.map((slotSelected) => {
        const nameScheduled = `${slotSelected.Patient.name} ${slotSelected.Patient.lastName}`;
        let birthDateScheduled = slotSelected.Patient.birthDate;
        const documentScheduled = slotSelected.Patient.document;

        birthDateScheduled = moment(birthDateScheduled).format("YYYY-MM-DD");

        const slotOld = slotSelected.id;
        const slotSpecialtyOld = slotSelected.Specialty.id;

        if (documentCurrent) {
          if (
            slotSpecialtyCurrent === slotSpecialtyOld &&
            documentCurrent === documentScheduled
          ) {
            dispatch(appointmentOld(slotSelected));
            return slotOld;
          } else {
            return false;
          }
        } else if (
          slotSpecialtyCurrent === slotSpecialtyOld &&
          nameScheduled === nameCurrent &&
          birthDateScheduled === birthDateCurrent
        ) {
          dispatch(appointmentOld(slotSelected));
          return slotOld;
        } else {
          return false;
        }
      });
      let verifier = verifySlot.find((element) => element != false);
      if (verifier) {
        setSelectedSlot(slotId);
        setModalConfirm(true);
      } else {
        if (serviceVonageEnabled.scheduling) {
          dispatch(checkPatient({ patient, slot: { slotId: slotId } }));
        } else {
          dispatch(
            checkPatientTruclinic({
              patient: { ...patient, empresas: [provider] },
              slot: { slotId: slotId },
            })
          );
        }
      }
    } else {
      if (serviceVonageEnabled.scheduling) {
        dispatch(checkPatient({ patient, slot: { slotId: slotId } }));
      } else {
        dispatch(
          checkPatientTruclinic({
            patient: { ...patient, empresas: [provider] },
            slot: { slotId: slotId },
          })
        );
      }
    }
  }

  const handleClose = React.useCallback(
    async (confirm) => {
      patient.empresas = [provider];
      if (confirm) {
        if (serviceVonageEnabled.scheduling) {
          dispatch(setLoading(true));
          const scheduleId = appointmentsOld.id;
          await apiAps
            .delete(
              `/tele-scheduling/appointments/vonage/session/${scheduleId}`
            )
            .then((response) => {
              dispatch(cancelSchedulingSuccess(response.data));
              dispatch(checkPatient({ patient, slot: { slotId: slot } }));
            })
            .catch((error) => {
              dispatch(setLoading(false));
              show(
                "Não foi possível realizar o cancelamento. Tente novamente mais tarde."
              );
            });
        } else {
          dispatch(
            checkPatientTruclinic({
              patient: { ...patient, empresas: [provider] },
              slot: { slotId: slot },
            })
          );
          dispatch(cancelSchedulingTruclinic(appointmentsOld.id));
        }

        setModalConfirm(false);
      } else {
        setModalConfirm(false);
      }
    },
    [dispatch, appointmentsOld, cancelSchedule]
  );

  function goToScheduled() {
    window.location.href = "/consultas-agendadas";
  }
  function goToHome() {
    setSelectedPatient(false);
    window.history.back();
  }

  function arraySlotMapped(schedule, appointments) {
    let arrSched = [];
    let scheduled = appointments;
    var scheduleArray = schedule,
      seen = Object.create(null),
      result = scheduleArray.filter((o) => {
        var key = ["startAt"].map((k) => o[k]).join("|");
        if (!seen[key]) {
          seen[key] = true;
          return true;
        }
      });
    arrSched = result;

    if (scheduled && scheduled.length > 0) {
      arrSched = [];
      let patientSchedule = patient;

      if (patient.holderId) {
        patientSchedule.firstNameSchedule = patient.firstName;
      } else {
        patientSchedule.firstNameSchedule = patient.name;
      }

      for (let arr of result) {
        let exists = false;
        for (let schedule of scheduled) {
          if (
            arr.finishAt === schedule.finishAt &&
            patient.birthDate === schedule.Patient.birthDate.split(" ")[0] &&
            patientSchedule.firstNameSchedule === schedule.Patient.name
          ) {
            exists = true;
          }
        }
        if (!exists) arrSched.push(arr);
      }
    }
    return arrSched;
  }

  function getUserPicture() {
    if (patient.email && patient.image) return patient.image;

    if (patient.signUpDocuments && patient.signUpDocuments.image)
      return patient.signUpDocuments.image;

    return "img/default.png";
  }

  const leadTerms = useSelector(
    (state) => state.termsProvider.patientLeadTermsFiltered
  );

  const filterLeadId = (propertyName) => {
    return leadTerms[propertyName]?.id || 0;
  };

  const teleconsultingTermId = filterLeadId("teleConsultation");

  return (
    <div>
      <BodySchedule>
        {successScheduled && (
          <HeaderScheduled>
            <Header>
              {/* <DivTitle>
              <TitleScheduled>Agendamento</TitleScheduled>
              <SubTitleSchedule>Agende um novo atendimento</SubTitleSchedule>
            </DivTitle> */}
              <BodyScheduled>
                <TitleScheduled>
                  Agendamento efetuado com sucesso !
                </TitleScheduled>
                <Img src="img/verification.png" />
                <SubTitleScheduled>
                  Em caso de dúvidas, entre em contato:
                </SubTitleScheduled>
                <DivScheduled>
                  <GridScheduled>
                    <Icon src="img/icon@.png" />
                    <DivLabels>
                      <LabelSchedule>Por e-mail</LabelSchedule>
                      <SubLabelSchedule>
                        canaisdigitais@einstein.br
                      </SubLabelSchedule>
                    </DivLabels>
                    <Icon src="img/phone.png" />
                    <DivLabels>
                      <LabelSchedule>Por telefone</LabelSchedule>
                      <SubLabelSchedule>(11) 2151-7690</SubLabelSchedule>
                    </DivLabels>
                  </GridScheduled>
                </DivScheduled>

                <ButtonOk
                  onClick={() => {
                    goToScheduled();
                  }}
                >
                  OK
                </ButtonOk>
              </BodyScheduled>
            </Header>
          </HeaderScheduled>
        )}

        {!successScheduled && (
          <Grid>
            <Header>
              <TitleScheduled>Paciente selecionado</TitleScheduled>

              <ChoicePatient>
                <PatientSelected>
                  <ImagePatientSelected src={getUserPicture()} />
                  <InfoPatientSelected>
                    <NamePatientSelected>
                      {NameHelper.getSocialNameOrName(patient, user)}
                    </NamePatientSelected>
                    <NivelPatientSelected>
                      {patient.isHolder === false ? "Dependente" : "Titular"}
                    </NivelPatientSelected>
                  </InfoPatientSelected>
                </PatientSelected>
              </ChoicePatient>

              {product && !product.suggestsSpecialty && (
                <DivFilters>
                  <GridFilterSpecialty>
                    <GridContainer>
                      <CircleSchedule>1</CircleSchedule>
                      <DivLabels>
                        <LabelSchedule>Selecione a especialidade</LabelSchedule>
                        <SubLabelSchedule>
                          Selecione a especialidade desejada:
                        </SubLabelSchedule>
                      </DivLabels>
                    </GridContainer>
                    <DivSelect>
                      <SelectSpecialtyDoctor>
                        <LabelSelect>
                          <SubLabelSchedule>Especialidade</SubLabelSchedule>
                        </LabelSelect>
                        <Select
                          placeholder="Selecione a especialidade"
                          options={specialty}
                          onChange={changeSpecialty}
                        ></Select>
                      </SelectSpecialtyDoctor>
                    </DivSelect>
                  </GridFilterSpecialty>

                  <GridFilterDoctor>
                    <GridContainer>
                      <CircleSchedule>2</CircleSchedule>
                      <DivLabels>
                        <LabelSchedule>Selecione o profissional</LabelSchedule>
                        <SubLabelSchedule>
                          Selecione o profissional desejado:
                        </SubLabelSchedule>
                      </DivLabels>
                    </GridContainer>
                    <DivSelect>
                      <SelectSpecialtyDoctor>
                        <LabelSelect>
                          <SubLabelSchedule>Profissional</SubLabelSchedule>
                        </LabelSelect>
                        <Select
                          placeholder="Selecione o profissional"
                          options={doctorsProps}
                          onChange={changeDoctor}
                        ></Select>
                      </SelectSpecialtyDoctor>
                    </DivSelect>
                  </GridFilterDoctor>
                </DivFilters>
              )}
            </Header>

            {!product ||
              (product && !product.suggestsDate && (
                <ScheduleGrid>
                  <ScheduleDay>
                    <GridContainer>
                      <CircleSchedule>3</CircleSchedule>
                      <DivLabels>
                        <LabelSchedule>Selecione a data</LabelSchedule>
                        <SubLabelSchedule>
                          Selecione o dia que gostaria de ser atendido
                        </SubLabelSchedule>
                      </DivLabels>
                    </GridContainer>

                    <DateInputDiv>
                      <DateInput
                        placeholder="Date"
                        popupPosition="bottom right"
                        className="example-calendar-input"
                        name="date"
                        closable
                        inline
                        animation="scale"
                        duration={200}
                        hideMobileKeyboard
                        value={date}
                        iconPosition="left"
                        minDate={moment()}
                        maxDate={moment().add(3, "M")}
                        marked={date}
                        markColor={"blue"}
                        preserveViewMode={false}
                        autoComplete="off"
                        onChange={handleChange}
                      />
                    </DateInputDiv>
                  </ScheduleDay>
                  <ScheduleHour>
                    <GridContainer>
                      <CircleSchedule>4</CircleSchedule>
                      <DivLabels>
                        <LabelSchedule>Selecione o horário</LabelSchedule>
                        <SubLabelSchedule>
                          Selecione o horário que gostaria de ser atendido
                        </SubLabelSchedule>
                      </DivLabels>
                    </GridContainer>

                    <AvailableHours>
                      {hourFormat &&
                        arraySlotMapped(hourFormat, appointments).map(
                          (slot) => (
                            <HourButton
                              active={slot.id === keyHourSelected}
                              onClick={() => {
                                selectHour(slot.id);
                              }}
                            >
                              {slot.startAt}
                            </HourButton>
                          )
                        )}
                    </AvailableHours>
                  </ScheduleHour>
                </ScheduleGrid>
              ))}
            {product && product.suggestsDate && (
              <ScheduleGrid>
                <ScheduleHour>
                  <GridContainer>
                    <DivLabels>
                      <LabelSchedule>Selecione o horário</LabelSchedule>
                      <SubLabelSchedule>
                        O sistema permite agendamento apenas para o mesmo dia
                        das 08:00 às 23:30.
                      </SubLabelSchedule>
                    </DivLabels>
                  </GridContainer>

                  <AvailableHours>
                    {hourFormat &&
                      arraySlotMapped(hourFormat, appointments).map(
                        (slot, key) => (
                          <HourButton
                            key={key}
                            active={slot.id === keyHourSelected}
                            onClick={() => {
                              selectHour(slot.id);
                            }}
                          >
                            {slot.startAt}
                          </HourButton>
                        )
                      )}
                    {notFoundSlots && (
                      <TitleMessage>
                        Não há mais horários disponíveis para agendamento.
                        Retorne à tela anterior e clique em iniciar atendimento.
                        Você será atendido pelo primeiro médico disponível.
                      </TitleMessage>
                    )}
                  </AvailableHours>
                </ScheduleHour>
              </ScheduleGrid>
            )}
            {/* <div style={{ padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              <input style={{
                marginRight: 5,
              }} type="checkbox" id="termo" value={checked} onClick={() => setChecked(!checked)} />
              <label style={{
                color: '#666666',
                flexWrap: 'wrap',
                fontSize: 10,
              }}>Ao iniciar o atendimento, você concorda com o <label style={{
                color: '#0274D2',

              }} onClick={() => { window.location.href = '/teleconsulta/termo' }}>Termo de uso obrigatório da Teleconsulta.</label></label>
            </div> */}
            {successSlots ? (
              <ButtonAdvance
                onClick={async () => {
                  if (!keyHourSelected || keyHourSelected === "") {
                    return show("Selecione a data/hora do seu atendimento");
                  }

                  if (schedule) {
                    schedule.map((sche) => {
                      if (sche.id === keyHourSelected) {
                        // if (teleconsultingTermsEnabled) {
                        //   dispatch(
                        //     postPatientLeadTerms({
                        //       termId: teleconsultingTermId,
                        //       status: TERMS_STATUS.ACCEPTED,
                        //     })
                        //   );
                        // }
                        !confirmScheduling(sche);
                      }
                    });
                  } else show("Selecione a data/hora do seu atendimento");
                }}
              >
                Avançar
              </ButtonAdvance>
            ) : (
              <ButtonAdvance onClick={() => goToHome()}>Voltar</ButtonAdvance>
            )}
          </Grid>
        )}
      </BodySchedule>
      <Confirm
        isOpen={modalConfirm}
        handleClose={handleClose}
        onClose={() => setModalConfirm(false)}
      />
      <Patient isOpen={modalPatient} onClose={() => setModalPatient(false)} />
    </div>
  );
}
export default Telemedicina;

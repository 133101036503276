import React, { useEffect, useState } from "react";
import Loading from "../../components/loading";

import Container from "../../container";
import {
  Main,
  ContainerHistory,
  DivHistory,
  DateHour,
  First,
  Second,
  Header,
  ButtonDocuments,
  TitleScheduled,
  SubTitleSchedule,
  DivTitle,
  TitleSchedule,
  Infos,
  SelectPatient,
  DivSelect,
  ContainerInput,
  InputGroup,
  Label,
  Input,
  WrappedInputs,
  ErrorLabel,
} from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../../redux/actions/history.actions";
import useDebounce from "./useDebounce";

import ModalHistory from "./modal-history";
import { getCredentials } from "../../utils/credentials";

export default function App() {
  const dispatch = useDispatch();

  const historyList = useSelector((state) => state.historyReducer.historyList);
  const user = useSelector((state) => state.userReducer.user);
  const [modalHistory, setModalHistory] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorLabel, setErrorLabel] = useState("");

  const debouncedStartDate = useDebounce(startDate, 2000);
  const debouncedEndDate = useDebounce(endDate, 2000);

  useEffect(() => {
    dispatch(
      getHistory({
        documentNumber: user?.document,
        documentType: user?.documentType,
        medicalRecord: user?.medicalRecord,
        token: getCredentials().patientToken,
      })
    );
  }, []);

  function openDocuments(history) {
    if (history && history.documents.length > 0) {
      setModalHistory(history);
      setOpenModal(true);
    }
  }

  function formatDate(date) {
    return date.split("T")[0];
  }

  function handlePatient({ target }) {
    const parsedPatient = JSON.parse(target.value);

    const data = {
      documentNumber: parsedPatient.document,
      namePatient: `${
        parsedPatient.name
          ? parsedPatient.name
          : parsedPatient.firstName + " " + parsedPatient.lastName
      }`,
    };

    dispatch(getHistory(data));
  }

  function handleStartDateChange(e) {
    setStartDate(e.target.value);
  }

  function handleEndDateChange(e) {
    setEndDate(e.target.value);
  }

  useEffect(() => {
    if (debouncedStartDate && debouncedEndDate) {
      if (new Date(debouncedStartDate) > new Date(debouncedEndDate)) {
        setErrorLabel("A data inicial não pode ser maior do que a data final.");
      } else {
        setErrorLabel("");
        dispatch(
          getHistory({
            documentNumber: user?.document,
            documentType: user?.documentType,
            medicalRecord: user?.medicalRecord,
            token: getCredentials().patientToken,
            startDate: debouncedStartDate,
            endDate: debouncedEndDate,
          })
        );
      }
    }
  }, [debouncedStartDate, debouncedEndDate, dispatch, user]);

  if (historyList && historyList.length < 0) {
    return <Loading />;
  }

  const getUserName = () => {
    if (user?.diversity?.socialName) {
      return user.diversity.socialName;
    }

    return user.name ? user.name : user.firstName + " " + user.lastName;
  };

  return (
    <Container>
      <Main>
        <ContainerHistory>
          <DivTitle>
            <TitleSchedule>Histórico de consultas</TitleSchedule>
            <SubTitleSchedule>
              Consulte as informações das suas consultas
            </SubTitleSchedule>
          </DivTitle>
          <WrappedInputs>
            <SelectPatient>
              <h4>Selecionar paciente</h4>
              <span>
                Selecione o paciente para visualizar a documentação dos
                atendimentos.
              </span>

              <DivSelect htmlFor="selectpatient">
                <select id="selectpatient" onChange={(e) => handlePatient(e)}>
                  <option label={getUserName()} value={JSON.stringify(user)} />
                  {historyList && historyList.length > 0
                    ? historyList.map((item, index) => {
                        if (
                          item?.dependents &&
                          item?.dependents?.document !==
                            user?.document.replace(/([^0-9a-zA-Z])/gi, "")
                        ) {
                          return (
                            <option
                              key={index}
                              label={item?.dependents.name || ""}
                              value={JSON.stringify(item?.dependents)}
                            />
                          );
                        }
                        return;
                      })
                    : null}
                </select>
                <img src="img/arrow-down.png" />
              </DivSelect>
            </SelectPatient>
            <ContainerInput>
              <InputGroup>
                <Label htmlFor="startDate">Data inicial:</Label>
                <Input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e)}
                />
              </InputGroup>
              <InputGroup>
                <Label htmlFor="endDate">Data final:</Label>
                <Input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e)}
                />
              </InputGroup>
            </ContainerInput>
            {errorLabel !== "" && (
              <ErrorLabel>{errorLabel && errorLabel}</ErrorLabel>
            )}
          </WrappedInputs>

          {(historyList || []).length === 0 && (
            <Header>
              <DivTitle>
                <TitleScheduled>
                  Você não realizou nenhuma consulta
                </TitleScheduled>
              </DivTitle>
            </Header>
          )}
          {(historyList || []).length < 0 ? (
            <Loading />
          ) : (
            historyList.map((item, key) => {
              return (
                <DivHistory key={key} onClick={() => openDocuments(item)}>
                  <First>
                    <DateHour>
                      <p>{formatDate(item.date)}</p>
                      <p>{item.hour}</p>
                    </DateHour>
                    <Infos>
                      <p>{item?.local ?? "Não informado"}</p>
                      <b>{item?.nameType ?? "Não informado"}</b>
                    </Infos>
                  </First>
                  {item.documents.length > 0 && (
                    <Second key={key}>
                      <ButtonDocuments>Ver documentos emitidos</ButtonDocuments>
                    </Second>
                  )}
                </DivHistory>
              );
            })
          )}
        </ContainerHistory>

        <ModalHistory
          history={modalHistory}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      </Main>
    </Container>
  );
}

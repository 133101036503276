import { call, put, all, select, take } from "redux-saga/effects";
import {
  setLoading,
  setLoaded,
  setLogged,
  setMenuVisible,
  verifyAuth,
  setListDiseases,
  openModalDependent,
  setDocsUploadStep,
  setDocsUploadSteps,
  setDocsUploadFiles,
  resetUploadDocs,
} from "../actions/utils.actions";

import { MODAL_PATIENT } from "../actions/action-types";

import { setPatient } from "../actions/patient.actions";

import {
  setUser,
  sendUploadDocuments,
  openModalPatient,
  fetchCurrentPrivatePlan,
  selectProvider,
  userSetCorporateCard,
} from "../actions/user.actions";
import { getAllDependents } from "../actions/depedents.actions";
import { show } from "../../services/alert";
import { push, goBack } from "../../services/navigate";
import { BsCardHeading } from "react-icons/bs";
import mime from "mime-types";

import api from "../../services/api";
import { clearCredentials, hasToken } from "../../utils/credentials";
import { LOGIN_ROUTE } from "../../routes/routes.contants";
import uploadDocumentsLocalStorage from "../../utils/uploadDocumentsLocalStorage";
import { apiMinhaConta } from "../../services/apiMinhaConta";
import { checkAuthFromPerson } from "../../utils/checkAuthFromPerson"

// provider selected
let provider = localStorage.getItem("@conecta:selected-provider");
provider = JSON.parse(provider);

export function* openModalRequest({ payload }) {
  yield put(setLoading(true));

  const idConvenio = provider.id_convenio;

  try {
    const request = () =>
      api.post(`/plans/getCurrentPlan/`, { idConvenio: idConvenio });
    const { data } = yield call(request);

    if (data && parseInt(data.dependentsCount) > 0) {
      if (payload && payload.length >= parseInt(data.dependentsCount)) {
        return show("Número de dependentes excedido.");
      }

      return yield put(openModalDependent(true));
    }

    if (data && data.messageDependents) {
      return show(data.messageDependents);
    }
    return show(
      "Não é possível a inclusão de dependentes, você possui o contrato individual"
    );
  } catch (error) {
    return show("Você não possui um contrato");
  } finally {
    yield put(setLoading(false));
  }
}

export function* checkTerms({ payload }) {
  yield put(setLoading(true));
  try {
    const request = () => api.post(`/user/term`, { checkTerms: true });
    yield call(request);

    let route = localStorage.getItem("@route");
    if (route == null || route == "/termos") {
      yield push("/provedores");
    } else {
      yield push(`${route}`);
    }
    localStorage.removeItem("@route");

    show("Termos de Uso e Política de Privacidade aceitos com sucesso");
  } catch (error) {
    show("Erro ao aceitar Termos de Uso");
  } finally {
    yield put(setLoading(false));
    yield put(setMenuVisible(true));
  }
}
export function* checkAuth({ payload }) {
  const token = hasToken();
  const id_particular = process.env.REACT_APP_ID_AGREEMENT_PARTICULAR;
  let provider = localStorage.getItem("@conecta:selected-provider");

  try {
    if (!token) throw new Error("Token not found");

    const getPerson = () => apiMinhaConta.get("/patient-portal/v1/person")

    const getElegiblility = () => apiMinhaConta.get("/patient-portal/v1/person/eligibility")
    let { data: person } = yield call(getPerson);
    let { data: elegibility } = yield call(getElegiblility);

    const data = { ...checkAuthFromPerson(person) }
    data.empresas = [  ...(elegibility[process.env.REACT_APP_CORPORATE_PRODUCT] || [])  , ...(elegibility[process.env.REACT_APP_TELEMEDICINA_ID] || []) ]

    data.empresasExterno = []

    data.checkElegibility = data.empresas.length > 0



    const patientSelected = JSON.parse(localStorage.getItem("@conecta:patient-selected"));
    if (patientSelected === null) {
      yield put(setPatient(data));
    }

    yield all([
      put(setUser(data)),
      put(getAllDependents()),
      put(setLogged(true)),
      put(setMenuVisible(true)),
      put(selectProvider(JSON.parse(provider))),
    ]);

    if (payload && payload.landing) {
      yield put(setMenuVisible(false));
    }

    // if (data?.checkTerms === false) {
    //   localStorage.setItem("@route", window.location.pathname);
    //   return push("/termos");
    // }

    // Verifica se a primeira empresa na lista de empresas é a desejada
    if (data?.empresas[0]?.produto && data?.empresas[0]?.produto === "5da4d2a958527a17184f95a6") {
      // Define o valor do local storage "provider"
      localStorage.setItem("provider", "fundacao-itau");
    }

    yield put(userSetCorporateCard(true));

    if (!provider) return;

    provider = JSON.parse(provider);

    if (provider.id_convenio == id_particular)
      yield put(fetchCurrentPrivatePlan(provider.id_convenio));

    // if (
    //   provider?.produto && provider?.produto == process.env.REACT_APP_CORPORATE_PRODUCT &&
    //   (provider.subgrupo == "OURO" || provider.subgrupo == "PRATA")
    // ) {
    //   yield put(userSetCorporateCard(true));
    // }

    if (provider.validar_tipo_elegibilidade == 0) {
      const hasElegibility = data.empresas.some(
        (item) => item.id_convenio == provider.id_convenio
      );
      if (
        !hasElegibility &&
        window.location.pathname != "/login/payment_confirmed"
      ) {
        push("/provedores");
      }
    }
  } catch (error) {
    localStorage.removeItem("@conecta:patient-holder");
  } finally {
    yield all([put(setLoading(false)), put(setLoaded(true))]);
  }
}


export function* buyAuth() {
  const buyToken = localStorage.getItem("@buy_token");

  try {
    if (!buyToken) throw new Error("Token not found");

    const request = () => api.get("/buy-auth");
    const { data } = yield call(request);
    yield all([put(setUser(data)), put(setMenuVisible(false))]);
  } catch (error) {
    window.location.href = LOGIN_ROUTE;
    yield localStorage.removeItem("@buy_token");
    yield put(verifyAuth());
  } finally {
    yield all([put(setLoading(false)), put(setLoaded(true))]);
  }
}

export function* generateBuyToken() {
  try {
    const request = () => api.get("/buy-auth/generate");
    const { data } = yield call(request);

    if (data) {
      yield all([
        localStorage.removeItem("@current_plan"),
        put(setMenuVisible(false)),
        localStorage.setItem("@conecta:topbar-redirect-scroll", "#plans"),
        push("/"),
      ]);
    }
  } catch (error) {
    localStorage.removeItem("@buy_token");
  } finally {
    yield all([put(setLoading(false)), put(setLoaded(true))]);
  }
}

export function* getDiseases() {
  try {
    const request = () => api.get("/diseases");
    const { data } = yield call(request);

    yield put(setListDiseases(data.diseases));
  } catch (error) {
  } finally {
    yield all([put(setLoading(false))]);
  }
}

export function* sendEmail({ payload }) {
  yield put(setLoading(true));

  try {
    const request = () => api.post("/sendmail", payload);
    yield call(request);
    yield push("/home");
    show("Mensagem enviada com sucesso");
  } catch (error) {
    show("Erro ao mandar mensagem");
  } finally {
    yield put(setLoading(false));
  }
}

export function* sendCancelRequest({ payload }) {
  yield put(setLoading(true));

  let data = {
    name: payload.name,
    cpf: payload.document,
    phone: payload.phone,
    email: payload.email,
    text: payload.message,
  };

  try {
    const request = () => api.post("/email-cancellation-plan", data);
    const res = yield call(request);
    if (res.data.errorMsg) {
      show(res.data.message);
    } else {
      yield push("/home");
      show("Solicitação de cancelamento enviada com sucesso");
    }
  } catch (error) {
    show("Erro ao mandar mensagem");
  } finally {
    yield put(setLoading(false));
  }
}

export function* reSendEmail({ payload }) {
  yield put(setLoading(true));

  try {
    const request = () => api.post("/user/resend", payload);
    yield call(request);
    show("E-mail reenviado com sucesso");
  } catch (error) {
    show("Erro ao mandar mensagem");
  } finally {
    yield put(setLoading(false));
  }
}

export function* goToNextUploadStep({ payload }) {
  const { currentStep, steps, files, skipProfilePic, selectedDocumentType } =
    yield select((state) => state.utilsReducer.documentsUpload);

  const stepId = steps[currentStep].id;
  const stepsWithFile = new Set(
    files.map((elem) => elem.originalName.split("-")[0])
  );

  if (stepId === "PROFILE_PIC") {
    if (skipProfilePic || stepsWithFile.has(stepId))
      yield put(setDocsUploadStep(currentStep + 1));
  } else if (stepId === "SELECT_DOCUMENT") {
    if (selectedDocumentType) yield put(setDocsUploadStep(currentStep + 1));
  } else if (currentStep === steps.length - 1) {
    yield put(sendUploadDocuments({ dependent: payload.dependent }));
  } else if (stepsWithFile.has(stepId)) {
    yield put(setDocsUploadStep(currentStep + 1));
  }
}

export function* undoDocUploadStep({ payload }) {
  const { currentStep } = yield select(
    (state) => state.utilsReducer.documentsUpload
  );

  const targetStep = Math.max(currentStep - 1, 0);
  yield put(setDocsUploadStep(targetStep));
}

export function* uploadDocTypeSelected({ payload }) {
  const { steps, files, skipProfilePic } = yield select(
    (state) => state.utilsReducer.documentsUpload
  );

  const addIdBackStep = () => [
    ...steps,
    {
      id: "ID_BACK",
      title: "Verso do documento",
      subtitle: "Inclua uma foto do verso do documento selecionado",
      icon: BsCardHeading,
    },
  ];

  const removeIdBackStep = () => steps.filter((elem) => elem.id !== "ID_BACK");

  let newSteps;
  if (payload == "RG") newSteps = addIdBackStep();
  else newSteps = removeIdBackStep();

  yield put(setDocsUploadSteps(newSteps));

  const documentSelectionStepIdx = steps.findIndex(
    (elem) => elem.id === "SELECT_DOCUMENT"
  );

  if (documentSelectionStepIdx !== -1)
    yield put(setDocsUploadFiles(files.slice(0, documentSelectionStepIdx)));

  // if user skiped profile pic we have one less file
  // than normally expected
  if (documentSelectionStepIdx !== -1 && skipProfilePic)
    yield put(setDocsUploadFiles(files.slice(0, documentSelectionStepIdx - 1)));
}

export function* currentDocumentStepFileSelected({ payload }) {
  const { steps, currentStep, files } = yield select(
    (state) => state.utilsReducer.documentsUpload
  );

  const extWhitelist = ["jpeg", "jpg", "png"];
  const originalFile = payload.files[0];
  const bits = originalFile.name.split(".");

  if (bits.length < 2) {
    show(`Formato de arquivo desconhecido. Por favor, selecione uma imagem.`);
    return;
  }

  const fileExt = bits[bits.length - 1].toLowerCase();
  const isSupported = extWhitelist.some((ext) => fileExt === ext);

  if (!isSupported) {
    show(
      `Formato de arquivo (${fileExt}) não suportado. Por favor, selecione uma imagem.`
    );
    return;
  }

  const currentStepId = steps[currentStep].id;

  function randomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    return characters
      .split("")
      .map((_) =>
        characters.charAt(Math.floor(Math.random() * characters.length))
      )
      .join("");
  }

  const newName = `${payload.file.id === "EXAM" ? "EXAM" : currentStepId
    }-${randomString()}.${mime.extension(originalFile.type)}`;

  const renamedFile = {
    ...originalFile,
    type: originalFile.type,
    originalName: newName,
    size: originalFile.size,
    preview: URL.createObjectURL(originalFile),
    url: null,
  };

  const existingIdx = files.findIndex((elem) =>
    elem.originalName.includes(currentStepId)
  );

  const newStructureImageBucket = {
    documentImage: {
      lastModified: originalFile.lastModified,
      lastModifiedDate: originalFile.lastModifiedDate,
      name: originalFile.name,
      size: originalFile.size,
      type: originalFile.type,
      webkitRelativePath: originalFile.webkitRelativePath,
      url: URL.createObjectURL(originalFile),
    },
    documentName: newName,
    documentType: payload.file.id,
    idDocument: newName,
    imageSource: {
      lastModified: originalFile.lastModified,
      lastModifiedDate: originalFile.lastModifiedDate,
      name: originalFile.name,
      size: originalFile.size,
      type: originalFile.type,
      webkitRelativePath: originalFile.webkitRelativePath,
      url: URL.createObjectURL(originalFile),
    },
    size: originalFile.size,
  };

  if (existingIdx != -1) {
    yield put(
      setDocsUploadFiles([
        ...files.slice(0, existingIdx),
        renamedFile,
        ...files.slice(existingIdx, -1),
      ])
    );
  } else {
    yield put(setDocsUploadFiles([...files, renamedFile]));
  }
  const docs = yield JSON.parse(localStorage.getItem("conecta:docs"));
  if (payload.file.id === "EXAM") {
    if (docs === null) {
      localStorage.setItem(
        "conecta:docs",
        JSON.stringify([newStructureImageBucket])
      );
    } else {
      let newDocs = []
      docs.map(value => newDocs.push(value))
      newDocs.push(newStructureImageBucket)
      localStorage.setItem("conecta:docs", JSON.stringify(newDocs));
    }
  } else {
    uploadDocumentsLocalStorage(newStructureImageBucket, docs);
  }
}

export function* uploadDocsIgnored({ payload }) {
  yield push("/home");
  yield put(resetUploadDocs());
  yield take(MODAL_PATIENT);
  yield put(openModalPatient(false));
}

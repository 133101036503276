import React, { useState, useEffect } from "react";
import {
  ContainerMenu,
  HeaderMenu,
  LogoMenu,
  ButtonMenu,
  Menu,
  Logged,
  Options,
  OptionItem,
  User,
  NameUser,
  ImageUser,
  MenuList,
  MenuItem,
  MenuLabel,
} from "./styles";

import { FiMenu, FiX } from "react-icons/fi";

import itemsMenu from "../../utils/items-side-bar";
import api from "../../services/api";

import { push } from "../../services/navigate";
import { useDispatch, useSelector } from "react-redux";
import { openModalUser } from "../../redux/actions/user.actions";
import { changePlan } from "../../redux/actions/buy.actions";
import { verifyAuth } from "../../redux/actions/utils.actions";
import {
  setPatient,
  setSelectedPatient,
} from "../../redux/actions/patient.actions";
import { TERMS_PROVIDER_STATUS } from "../../pages/home/select-patient/terms-provider/constants";
import { getPatientLeadTerms, setTermsProvider } from "../../redux/actions/termsProvider.actions";
import { clearCredentials } from "../../utils/credentials";
import { LOGIN_ROUTE } from "../../routes/routes.contants";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";

export default function App() {
  const dispatch = useDispatch();

  const moduleHomeMicrofrontendEnabled = getRemoteConfigValue(
    "module_home_microfrontend_enabled"
  );
  const [showMenu, setShowMenu] = useState(false);
  const [activeRoute, setActiveRoute] = useState("/home");
  const [flagScheduling, setFlagScheduling] = useState(false);
  const provider = useSelector((state) => state.userReducer.selectedProvider);
  const user = useSelector((state) => state.userReducer.user);
  const hasTelemedicina = useSelector(
    (state) => state.userReducer.telemedicina
  );
  const hasCorporateCard = useSelector(
    (state) => state.userReducer.corporateCard
  );
  const hasTelepsicologia = useSelector(
    (state) => state.userReducer.telepsicologia
  );
  const hasTermsProvider = useSelector((state) => state.termsProvider.status);
  const hasCorporateCardFeatureEnabled = useSelector(
    (state) => state.featureFlagsReducer.featureFlags.corporateCardEnabled
  );
  const hasTermsProviderFeatureEnabled = useSelector(
    (state) => state.featureFlagsReducer.featureFlags.termsProviderEnabled
  );
  const host = window.location.href;

  function getFlagScheduling() {
    api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
    });
  }

  function _hasEligibility(product, scheduling) {
    if (scheduling) {
      if (!flagScheduling || provider.validar_tipo_elegibilidade != 0) {
        return false;
      }
    }

    switch (product) {
      case "TERMS_PROVIDER":
        return (
          hasTermsProvider &&
          hasTermsProvider !== TERMS_PROVIDER_STATUS.NOT_ELEGIBLE &&
          hasTermsProviderFeatureEnabled
        );
      case "TELEMEDICINA":
        return hasTelemedicina;
      case "CORPORATE_CARD":
        return hasCorporateCard && hasCorporateCardFeatureEnabled;
      case "TELEPSICOLOGIA":
        return hasTelepsicologia;
      case "ANY":
        return hasTelemedicina || hasTelepsicologia;
      default:
        return true;
    }
  }

  async function verifyPatientLeadTerms() {
    dispatch(getPatientLeadTerms());
  }


  useEffect(() => {
    getFlagScheduling();
    verifyPatientLeadTerms();

    const activeRoute = window.location.href.split("/")[3];

    if (!activeRoute) {
      return setActiveRoute(`/home`);
    }

    setActiveRoute(`/${activeRoute}`);
  }, []);

  const options = [
    {
      id: "HOME",
      label: "Home",
      action: () => goToHome(),
    },
    {
      id: "MEU_PERFIL",
      label: "Meu perfil",
      action: () => goToPerfil(),
    },
    {
      id: "ALTERAR_CONTRATO",
      label: "Alterar contrato",
      action: () => goToChangePlan(),
    },
    {
      id: "COMO_CANCELAR",
      label: "Como cancelar",
      action: () => goToCancel(),
    },
    {
      id: "TROCAR_PROVEDOR",
      label: "Trocar provedor",
      action: () => goToProvider(),
    },
    // {
    //   id: "TERMO_PRIVACIDADE",
    //   label: "Privacidade",
    //   action: () => goToPrivacy(),
    // },
    {
      id: "TROCAR_CARTAO_DE_CREDITO",
      label: "Trocar cartão de crédito",
      action: () => goToChangeCreditCard(),
    },
    {
      id: "SAIR",
      label: "Sair",
      action: () => logout(),
    },
  ];

  function goToChangePlan() {
    dispatch(changePlan());
    closeMenu();
  }

  function goToHome() {
    push("/home");
    closeMenu();
  }

  function goToPrivacy() {
    push("/privacidade");
    closeMenu();
  }

  function goToCancel() {
    push("/cancelar");
  }

  function goToChangeCreditCard() {
    push("/trocar-cartao");
  }

  function goToProvider() {
    push("/provedores");
    localStorage.removeItem("@conecta:selected-provider");
    localStorage.removeItem("@selected_provider_number_card")
  }

  function goToPerfil() {
    if (hasTelemedicina) {
      if (!moduleHomeMicrofrontendEnabled) {
        push("/home");
      }
    } else {
      push("/telepsicologia");
    }

    if (moduleHomeMicrofrontendEnabled) {
      localStorage.setItem("@home:open-profile-side", "true");
    } else {
      dispatch(openModalUser(true));
    }

    closeMenu();
  }

  function logout() {
    clearCredentials();
    window.location.href = LOGIN_ROUTE;
  }

  function openMenu() {
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  function changeRoute(infosRoute) {
    if (infosRoute.needEgilibility && !user.checkElegibility) {
      return;
    }
    //clear selected patient
    dispatch(setSelectedPatient(false));
    dispatch(setPatient({}));
    push(infosRoute.route);
    setActiveRoute(infosRoute.route);
    dispatch(verifyAuth());
  }

  function messageToltip(infosRoute) {
    if (infosRoute.needEgilibility && !user.checkElegibility)
      return "Não foi possível checar a sua elegibilidade, favor verificar se você possui algum contrato!";
  }

  function getMessage() {
    const dateNow = new Date();
    const hour = dateNow.getHours();

    if (hour < 5) {
      return "Boa noite";
    }

    if (hour < 12) {
      return "Bom dia";
    }

    if (hour < 18) {
      return "Boa tarde";
    }

    return "Bem-vindo";
  }

  function getCurrentOptions() {
    const hasPrivatePlan =
      provider.id_convenio == process.env.REACT_APP_ID_AGREEMENT_PARTICULAR;

    const isPlanAvulso = provider.subgrupo === "PLANO AVULSO B2C";

    if (isPlanAvulso)
      return options.filter((elem) => elem.id !== "TROCAR_CARTAO_DE_CREDITO");

    if (!hasPrivatePlan)
      return options.filter(
        (elem) =>
          elem.id !== "COMO_CANCELAR" && elem.id !== "TROCAR_CARTAO_DE_CREDITO"
      );

    return options;
  }

  function verifyProdAndDebug(itemMenu) {
    return (host.includes("telemedicina.einstein.br") && itemMenu.debug);
  }

  function verifyItemRemoteConfig(itemMenu) {
    if (verifyProdAndDebug(itemMenu)) return null 

    if (itemMenu?.remoteConfig){
      if(!getRemoteConfigValue(itemMenu?.remoteConfig)) return null;
    }

    return true;
  }

  return (
    <ContainerMenu>
      <HeaderMenu>
        <FiMenu
          style={{ pointerEvents: "all" }}
          size="25px"
          onClick={openMenu}
          color="#fff"
        />
        <LogoMenu src="/img/logo-einstein-white.png" />
      </HeaderMenu>

      <Menu showMenu={showMenu}>
        <Logged>
          <ButtonMenu onClick={closeMenu}>
            <FiX size="25px" color="#fff" />
          </ButtonMenu>

          <User>
            <NameUser>
              Olá, {getMessage()}. {user.name || user.firstName} {user.lastName}
            </NameUser>
            <ImageUser
              src={user.image || "/img/default.png"}
              onError={(e) => (e.target.src = "/img/default.png")}
              o
            />
          </User>

          <Options>
            {getCurrentOptions().map((option, key) => (
              <OptionItem
                key={key}
                latest={key + 1 === options.length}
                onClick={() => option.action()}
              >
                {option.label}
              </OptionItem>
            ))}
          </Options>
        </Logged>

        <MenuList>
          {itemsMenu.map((itemMenu, key) => {
            if (!verifyItemRemoteConfig(itemMenu))
              return null;
            else {
              return (
                <MenuItem
                  key={key}
                  active={itemMenu.route === activeRoute}
                  visible={_hasEligibility(
                    itemMenu.product,
                    itemMenu.needScheduling
                  )}
                  onClick={() => changeRoute(itemMenu)}
                  data-tip={messageToltip(itemMenu)}
                >
                  {itemMenu.icon && itemMenu.icon}
                  <MenuLabel active={itemMenu.route === activeRoute}>
                    {itemMenu.label}
                  </MenuLabel>
                </MenuItem>
              );
            }
          })}
        </MenuList>
      </Menu>
    </ContainerMenu>
  );
}

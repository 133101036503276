import { select, call, put } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";
import { getDependentEligibility } from "../sagas/dependents";
import { show } from "../../services/alert";
import api from "../../services/api";
import moment from "moment";
import apiAps from "../../services/apiAps";
import {
  successScheduled,
  slotByPatient,
  setLoadingSchedule,
} from "../actions/schedule.actions";
import { getDesktopData } from "../../utils/getDesktopData";
import {
  SCHEDULE_SUCESS,
  SPECIALTY_SUCESS,
  SCHEDULE_NOT_FOUND_SLOT,
  SCHEDULE_SUCCESS_SLOT,
  CONFIRM_SCHEDULING_SUCESS,
  DOCTOR_SUCESS,
  APPOINTMENTS_BY_PATIENT,
  CANCEL_SCHEDULING_SUCESS,
  PRODUCTS_SUCCESS,
} from "../actions/action-types";
import { push } from "../../services/navigate";
import { getRemoteConfigValue } from '../../hooks/RemoteConfig'

export function* findSlots(payload) {
  const { patientReducer } = yield select();
  const { schedule } = yield select();
  let { date, specialtyId = 1, doctorId } = payload.payload;
  const age = Math.floor(
    moment().diff(patientReducer.patient.birthDate, "year")
  );

  if (age < 16) specialtyId = 2;
  if (schedule.scheduleType === "TELEPSICOLOGIA") specialtyId = 3;

  yield put(setLoading(true));
  const dateNow = moment(date).format("YYYY-MM-DD");
  const data = {
    dateStart: dateNow,
    dateFinish: dateNow,
    state: 2,
    limit: 1000,
    offset: 0,
    specialtyId: specialtyId,
    doctorId: doctorId,
    scheduleType: schedule.scheduleType,
  };
  try {
    const request = () => api.post("/schedule/getAllSlots", data);
    const response = yield call(request);
    const date = response.data.data;
    if (response.status === 200) {
      const hourFormat = (date || [])
        .filter((data) => moment(data.startAt).isSameOrAfter(moment()))
        .map((data) => ({
          ...data,
          startAt: moment(data.startAt).format("HH:mm"),
        }));
      if (hourFormat.length > 0) {
        yield put({ type: SCHEDULE_NOT_FOUND_SLOT, payload: false });
        yield put({ type: SCHEDULE_SUCCESS_SLOT, payload: true });
      } else {
        yield put({ type: SCHEDULE_NOT_FOUND_SLOT, payload: true });
        yield put({ type: SCHEDULE_SUCCESS_SLOT, payload: false });
      }
      yield put({ type: SCHEDULE_SUCESS, payload: date });
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* findSpecialties({ payload }) {
  const dateNow = moment().format("YYYY-MM-DD");
  const dateFinish = moment().endOf("month").format("YYYY-MM-DD");

  const data = {
    dateStart: dateNow,
    dateFinish: dateFinish,
    state: 2,
    limit: 10000,
    offset: 0,
  };

  try {
    const request = () => api.post("/schedule/getAllSpecialties", data);
    const response = yield call(request);

    const oldSpecialty = response.data;

    const specialtyArray = oldSpecialty.map((item, key) => {
      return { key: item.id, value: item.id, text: item.name };
    });

    if (response.status === 200) {
      yield put({ type: SPECIALTY_SUCESS, payload: specialtyArray });
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  }
}

export function* findPatient({ payload }) {
  yield put(setLoading(true));
  try {
    const { patient, slot } = payload;
    const { userReducer } = yield select();

    const dependentEligibility = yield getDependentEligibility(patient._id);

    if(!dependentEligibility.hasEligibility){
      show(dependentEligibility.message);
      return
    }
    const empresas = [];
    const userProductsReduce = patient.empresas.reduce((acc, curr) => {
      if (!curr.length) {
        acc.push({
          [curr.produto]: [curr],
        });
      } else {
        acc.push({
          [curr[0].produto]: [...curr],
        });
      }

      return acc;
    }, []);

    const userProducts = Object.assign({}, ...userProductsReduce);

    patient.empresas.map((empresa) => {
      return empresas.push({
        id: String(empresa.id),
        id_convenio: String(empresa.id_convenio),
        descricao: empresa.descricao,
        produto: empresa.produto,
        validar_tipo_elegibilidade: String(empresa.validar_tipo_elegibilidade),
      });
    })

    const data = {
      email: patient.email,
      birthDate: patient.birthDate,
      document: patient.document,
      documentType: patient.documentType,
      fullName: patient.fullName,
      firstName: patient.firstName,
      lastName: patient.lastName,
      name: patient.name,
      phone: patient.phone || userReducer.user.phone || "5511988888888",
      rg: patient.rg || "000000000",
      sex: patient.sex,
      empresas,
      userProducts,
      slot,
      idPatientExt: patient._id,
      deviceData: getDesktopData(),
      sessionOrigin: "WEB - Agendamento"
    };
    const request = () =>
      apiAps.post(`/tele-scheduling/appointments-vonage`, { ...data });
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: CONFIRM_SCHEDULING_SUCESS, payload: response.data });
      yield put(successScheduled(true));
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* createSchedulingTruclinic({ payload }) {
  yield put(setLoading(true));
  const { schedule } = yield select();
  const scheduleType = schedule.scheduleType;
  try {
    const request = () =>
      api.post(
        "/schedule/scheduling",
        { ...payload, scheduleType },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: CONFIRM_SCHEDULING_SUCESS, payload: response.data });
      yield put(successScheduled(true));
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* cancelSchedulingTruclinic({ payload }) {
  const { userReducer } = yield select();
  yield put(setLoading(true));
  const slotId = payload;

  const data = {
    slotId: slotId,
    slotStateId: 7,
  };
  try {
    const request = () => api.post("/schedule/cancelScheduling", data);
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: CANCEL_SCHEDULING_SUCESS, payload: response.data });
      yield put(slotByPatient(userReducer.user));
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* findDoctorsBySpecialtyId({ payload }) {
  yield put(setLoading(true));
  const specialtyId = payload;
  const data = {
    idSpecialty: specialtyId,
  };
  try {
    const request = () =>
      api.post("/schedule/getAllDoctorsBySpecialtyId", data);
    const response = yield call(request);

    const doctors = response.data;

    const doctorsFormat = doctors.map((doctor) => {
      return { key: doctor.id, value: doctor.id, text: doctor.name };
    });

    if (response.status === 200) {
      yield put({ type: DOCTOR_SUCESS, payload: doctorsFormat });
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* findSLotByPatient({ payload }) {
  const featuresSelfScheduling = getRemoteConfigValue('features_self_scheduling')
  yield put(setLoadingSchedule(true));
  try {
    const request = () =>
      api.post(
        !!featuresSelfScheduling.modal_side_scheduling
          ? "/schedule/findSlotByPatientAll"
          : "/schedule/findSlotByPatient",
        {
          ...payload,
        }
      );
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: APPOINTMENTS_BY_PATIENT, payload: response.data });
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(setLoadingSchedule(false));
  }
}

export function* scheduleTelepsico() {
  yield put(setLoading(true));
  try {
    const { schedule, telepsico, patientReducer, userReducer } = yield select();
    let formattedPatient = patientReducer.patient;
    formattedPatient.empresas = [userReducer.selectedProvider];
    const data = {
      patient: formattedPatient,
      slot: { slotId: schedule.selectedSlot.id },
      scheduleType: schedule.scheduleType,
      programId: telepsico.selectedProgram._id,
    };

    const request = () => api.post("/schedule/firstTelepsicoSchedule", data);
    const response = yield call(request);

    console.log(response);

    if (response.status === 200) {
      yield put(successScheduled(true));
    }
  } catch (error) {
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* reschedule() {
  yield put(setLoading(true));
  try {
    const { schedule, patientReducer } = yield select();
    const data = {
      patient: patientReducer.patient,
      oldSlot: schedule.oldSlot,
      slot: schedule.selectedSlot,
      scheduleType: schedule.scheduleType,
    };
    const request = () => api.post("/schedule/reschedule", data);
    const response = yield call(request);
    if (response.status === 200) {
      push("/consultas-agendadas");
    }
  } catch (error) {
    if (error.response && error.response.data)
      show(`Erro: ${error.response.data.message}`);
    else show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* cancelScheduling({ payload }) {
  const { userReducer } = yield select();
  yield put(setLoading(true));
  const { scheduleId, channel } = payload;

  try {
    if (channel === "TELEMEDICINE") {
      const request = () =>
        apiAps.delete(
          `/tele-scheduling/appointments/vonage/session/${scheduleId}`
        );
      const response = yield call(request);
      if (response.status === 200) {
        yield put({ type: CANCEL_SCHEDULING_SUCESS, payload: response.data });
        yield put(slotByPatient(userReducer.user));
      }
    }
    if (channel === "SGH") {
      const request = () =>
        apiAps.delete(
          `/schedule/cancel?IdAgendamento=${scheduleId}&CancelaPassagem=true`
        );
      const response = yield call(request);
      if (response.status === 200) {
        yield put({ type: CANCEL_SCHEDULING_SUCESS, payload: response.data });
        yield put(slotByPatient(userReducer.user));
      }
    }
    if (channel === "SPECIALIST") {
      const request = () =>
        apiAps.delete(`specialists/cancel?appointmentId=${scheduleId}`);
      const response = yield call(request);
      if (response.status === 200) {
        yield put({ type: CANCEL_SCHEDULING_SUCESS, payload: response.data });
        yield put(slotByPatient(userReducer.user));
      }
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* findProducts({ payload }) {
  yield put(setLoading(true));
  const productId = 1; // refer telemedicina einstein

  try {
    const request = () => api.post("/schedule/findProducts", productId);
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: PRODUCTS_SUCCESS, payload: response.data.data });
    }
  } catch (error) {
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* configSystem({ payload }) {
  yield put(setLoading(true));

  try {
    const request = () => api.get("/config-system");
    const response = yield call(request);

    const flagScheduling = response.data.configSystem.scheduling;

    localStorage.setItem("@flag_scheduling", JSON.stringify(flagScheduling));
  } catch (error) {
    if (error) {
      show(error);
    }
  } finally {
    yield put(setLoading(false));
  }
}

import React, { useState, useEffect } from "react";
import {
  Main,
  Titles,
  Title,
  SubTitle,
  Infos,
  Info,
  LabelInfo,
  DivImageInfo,
  Form,
  DivTextArea,
  LabelTextArea,
  TextArea,
  ErrorMessage,
  DivButton,
  Button,
} from "./styles";
import { FiMail, FiPhoneCall } from "react-icons/fi";

import useYup from "@usereact/use-yup";
import { brasileiroSchema, estrangeiroSchema } from "./validate";
import Input from "../../components/input-custom";
import Container from "../../container";

import { sendEmail } from "../../redux/actions/utils.actions";
import { useDispatch, useSelector } from "react-redux";
import { getPhoneMaskRule } from "../../utils/phoneMask";

export default function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  const [maskPhoneRule, setMaskPhoneRule] = useState(getPhoneMaskRule(""));

  const [touched, setTouched] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
    document: "",
    socialName: "",
    documentType: "",
  });

  let { errors } = useYup(
    values,
    user.documentType === "PASSAPORTE" ? estrangeiroSchema : brasileiroSchema,
    {
      validateOnChange: true,
    }
  );

  useEffect(() => {
    if (user && user.email) {
      setValues({
        ...user,
        email: user.email,
        name: user.name,
        document: user.document,
        phone: user.phone,
        socialName: user.diversity?.socialName,
      });
    }

    if (user && user.phone) setMaskPhoneRule(getPhoneMaskRule(user.phone));
  }, []);

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setTouched({
      name: true,
      email: true,
      message: true,
      document: true,
      phone: true,
    });

    if (isEmpty(errors)) {
      dispatch(
        sendEmail({
          name: values.name,
          subject: "Suporte",
          email: values.email,
          document: values.document,
          phone: values.phone,
          text: values.text,
        })
      );
    }
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      [name]: true,
    });
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handlePhone(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setMaskPhoneRule(getPhoneMaskRule(value));
  }

  return (
    <Container>
      <Main>
        <Titles>
          <Title>Entre em contato</Title>
          <SubTitle>
            Estamos aqui para falar com você, sempre que precisar. Ficou com
            alguma dúvida? Então entre em contato conosco:
          </SubTitle>
        </Titles>

        <Infos>
          <Info>
            <DivImageInfo>
              <FiMail color="#fff" />
            </DivImageInfo>
            <LabelInfo>einsteinconecta@einstein.br</LabelInfo>
          </Info>
          <Info>
            <DivImageInfo>
              <FiPhoneCall color="#fff" />
            </DivImageInfo>
            <LabelInfo>(11) 2151-7690</LabelInfo>
          </Info>
        </Infos>

        <Form onSubmit={handleSubmit}>
          <Input
            width="450px"
            onChange={handleChange}
            name="name"
            placeholder="Nome"
            type="text"
            value={values.socialName ?? values.name}
            errorMessage={touched["name"] && errors && errors.name}
          />

          <Input
            width="450px"
            onChange={handlePhone}
            name="phone"
            placeholder={
              user.documentType === "PASSAPORTE"
                ? "Código do País + DDD + Telefone"
                : "DDD + Telefone"
            }
            type="text"
            mask={
              user.documentType === "PASSAPORTE"
                ? "+999999999999999"
                : maskPhoneRule.mask
            }
            value={values.phone}
            errorMessage={touched["phone"] && errors && errors.phone}
            maskChar={maskPhoneRule.maskChar}
          />

          <Input
            width="450px"
            onChange={handleChange}
            name="email"
            placeholder="E-mail"
            type="text"
            value={values.email}
            errorMessage={touched["email"] && errors && errors.email}
          />

          <Input
            width="450px"
            onChange={handleChange}
            name="document"
            placeholder={
              user.documentType === "PASSAPORTE" ? "Passaporte" : "CPF"
            }
            type="text"
            mask={user.documentType === "PASSAPORTE" ? "" : "999.999.999-99"}
            value={values.document}
            errorMessage={touched["document"] && errors && errors.document}
          />

          <DivTextArea>
            <LabelTextArea>Mensagem</LabelTextArea>
            <TextArea
              onChange={handleChange}
              name="text"
              type="text"
              value={values.text}
            />
            <ErrorMessage>
              {touched["text"] && errors && errors.text}
            </ErrorMessage>
          </DivTextArea>

          <DivButton>
            <Button type="submit">Enviar</Button>
          </DivButton>
        </Form>
      </Main>
    </Container>
  );
}

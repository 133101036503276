import React, { useState, useEffect } from "react";
import {
  SelectPatientBackgroundColor,
  Container,
  Layer,
  Main,
  Alert,
  AlertMessage,
  RowMain,
  InitCall,
  ButtonInit,
  ScheduleInit,
  AlertGroup,
} from "./styles";

import api from "../../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { FiAlertTriangle, FiCalendar } from "react-icons/fi";
import { push } from "../../../services/navigate";
import {
  openModalUser,
  openModalVerification,
  openModalPin,
  openModalPatient,
} from "../../../redux/actions/user.actions";

import {
  setPatient,
  setSelectedPatient,
  setPatientSentDocuments,
} from "../../../redux/actions/patient.actions";
import {
  setScheduleModal,
  setScheduleType,
} from "../../../redux/actions/schedule.actions";

import { setLabelWhite } from "../../../redux/actions/utils.actions";
import ReactTooltip from "react-tooltip";
import Profile from "../profile";
import Patient from "./modal-patient";
import VerificationTel from "./verification/verification-tel";
import VerificationPin from "./verification/verification-token";
import { verifyAuth } from "../../../redux/actions/utils.actions";
import { getAllDependents } from "../../../redux/actions/depedents.actions";

import AlertTermsProvider from "./terms-provider";
import { TERMS_PROVIDER_STATUS } from "./terms-provider/constants";
import ModalTermsProvider from "./terms-provider/modal";
import { setTermsProviderStatusAndTime } from "../../../redux/actions/termsProvider.actions";
import apiPatient from "../../../services/apiPatient";
import { getRemoteConfigValue } from "../../../hooks/RemoteConfig";

export default function SelectPatient(props) {
  const featuresSelfScheduling = getRemoteConfigValue(
    "features_self_scheduling"
  );
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const modalUser = useSelector((state) => state.userReducer.modalUser);
  const modalVerification = useSelector(
    (state) => state.userReducer.modalVerification
  );
  const modalPin = useSelector((state) => state.userReducer.modalPin);
  const modalPatient = useSelector((state) => state.userReducer.modalPatient);
  const dependentsProps = useSelector(
    (state) => state.dependentsReducer.dependents
  );
  const [flagScheduling, setFlagScheduling] = useState(false);
  const [showMessageDocument, setShowMessageDocument] = useState(false);
  const openScheduleModal = useSelector(
    (state) => state.schedule.openScheduleModal
  );
  const [isOpenedModalTermsProvider, setIsOpenedModalTermsProvider] =
    useState(false);
  const termsProvider = useSelector((state) => state.termsProvider);
  const hasTermsProviderFeatureEnabled = useSelector(
    (state) => state.featureFlagsReducer.featureFlags.termsProviderEnabled
  );
  function getFlagScheduling() {
    api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
    });
  }

  let provider = localStorage.getItem("@conecta:selected-provider");
  provider = JSON.parse(provider);

  // function messageToltip(elegibility) {
  //   if (!elegibility)
  //     return "Não foi possível checar a sua elegibilidade, favor verificar se você possui algum contrato!";

  //   return "Clique aqui para iniciar atendimento";
  // }

  // function goToBuy() {
  //   dispatch(generateBuyToken());
  // }

  function messageSchedule(elegibility) {
    if (!elegibility)
      return "Não foi possível checar a sua elegibilidade, favor verificar se você possui algum contrato!";
    return "Clique aqui para agendar uma consulta";
  }

  function goToSchedule() {
    dispatch(setScheduleType("TELEMEDICINA"));
    dispatch(openModalPatient(true));
    push("/consultas-agendadas");
    dispatch(verifyAuth());
  }

  function goToDocuments() {
    push("/documentos");
  }

  function AlertTermsProviderFilter(prop) {
    const {
      data: { status, enabled },
      onClose,
    } = prop;
    if (!enabled || !status || status === TERMS_PROVIDER_STATUS.NOT_ELEGIBLE)
      return null;
    return <AlertTermsProvider status={status} onClose={onClose} />;
  }

  function openModalToChangeTermsProvider() {
    setIsOpenedModalTermsProvider(true);
  }

  async function closeModalTermsProvider(termsChoice) {
    const status = termsChoice;

    if (status !== TERMS_PROVIDER_STATUS.INITIAL) {
      dispatch(
        setTermsProviderStatusAndTime({
          status,
          lastAccepted: new Date().toISOString(),
        })
      );
    }
    setIsOpenedModalTermsProvider(false);
  }

  async function verifyElegibilityTermsProvider() {
    localStorage.removeItem("conecta:docs");
    const { status } = termsProvider || {};
    if (
      !status ||
      !hasTermsProviderFeatureEnabled ||
      status === TERMS_PROVIDER_STATUS.NOT_ELEGIBLE ||
      status === TERMS_PROVIDER_STATUS.REJECTED ||
      status === TERMS_PROVIDER_STATUS.ACCEPTED
    ) {
      initVideoCall();
    } else if (status === TERMS_PROVIDER_STATUS.INITIAL) {
      openModalToChangeTermsProvider();
    }
  }

  const getDependents = React.useCallback(() => {
    dispatch(getAllDependents());
  }, [dispatch]);

  const initVideoCall = React.useCallback(async () => {
    dispatch(verifyAuth());
    if (dependentsProps && dependentsProps.length > 0) {
      dispatch(openModalPatient(true));
    } else {
      dispatch(setPatient(user));
      if (user.checkElegibility && provider.validar_tipo_elegibilidade == 0) {
        dispatch(setSelectedPatient(true));
      }
      if (provider && provider.validar_tipo_elegibilidade === 2) {
        dispatch(openModalVerification(true));
      }
    }
  }, [dispatch, dependentsProps, user, provider]);

  const validateDocumentsPatient = React.useCallback(async () => {
    const patientSelected = JSON.parse(
      localStorage.getItem("@conecta:patient-selected")
    );
    const isDependent = patientSelected._id !== user._id;
    const idPatient = isDependent
      ? patientSelected._id
      : patientSelected.idPatientConecta;
    const responseGetDocuments = await apiPatient
      .post(`/uploads/documents`, {
        id: idPatient,
        document: patientSelected?.document,
      })
      .then((response) => {
        if (response.data.patient.fileDocuments.length > 0) {
          return {
            documents: response.data.fileDocuments,
            existsDocuments: true,
          };
        } else {
          return {
            documents: null,
            existsDocuments: false,
          };
        }
      })
      .catch(() => {
        return {
          documents: null,
          existsDocuments: false,
        };
      });
    dispatch(setPatientSentDocuments(responseGetDocuments.existsDocuments));
    setShowMessageDocument(responseGetDocuments.existsDocuments);
  }, [dispatch, showMessageDocument]);

  useEffect(() => {
    getDependents();
  }, [getDependents]);

  useEffect(() => {
    getFlagScheduling();
    dispatch(setLabelWhite(true));
    return () => dispatch(setLabelWhite(false));
  }, []);

  useEffect(() => {
    validateDocumentsPatient();
  }, [user, validateDocumentsPatient]);

  return (
    <SelectPatientBackgroundColor>
      <Container>
        <ModalTermsProvider
          isOpen={isOpenedModalTermsProvider}
          onClose={closeModalTermsProvider}
        />
        <Layer />
        <Main>
          {/*
         {!user.checkElegibility && (
          <Alert>
            <AlertMessage>
              <FiAlertTriangle color={"#fff"} />
              <p>Você não tem um contrato ativo, adquira um contrato.</p>
            </AlertMessage>

            <button onClick={() => goToBuy()}>Ir para contratos</button>
          </Alert>
         )}*/}

          <AlertGroup>
            <AlertTermsProviderFilter
              onClose={openModalToChangeTermsProvider}
              data={{
                enabled: hasTermsProviderFeatureEnabled,
                status: termsProvider.status,
              }}
            />

            {!showMessageDocument && (
              <Alert marginTop={10}>
                <AlertMessage>
                  <FiAlertTriangle color={"#2196F3"} />
                  <p>
                    Você ainda não fez upload de documentos pessoais, complete
                    seu cadastro para uma melhor experiência.
                  </p>
                  <button onClick={goToDocuments}>Ir para documentos</button>
                </AlertMessage>
              </Alert>
            )}
          </AlertGroup>

          <RowMain>
            <InitCall>
              <ButtonInit
                // elegibility={user.checkElegibility}
                // data-tip={messageToltip(user.checkElegibility)}
                onClick={verifyElegibilityTermsProvider}
              >
                Iniciar Atendimento
              </ButtonInit>

              <ScheduleInit
                elegibility={user.checkElegibility}
                notElegibilityInternal={
                  provider.validar_tipo_elegibilidade != 0
                }
                data-tip={messageSchedule(user.checkElegibility)}
                flagScheduling={flagScheduling}
                onClick={() => {
                  localStorage.removeItem("conecta:docs");
                  if (featuresSelfScheduling.modal_side_scheduling) {
                    dispatch(setScheduleModal(!openScheduleModal));
                  } else {
                    goToSchedule();
                  }
                }}
                disabled={!user.checkElegibility}
              >
                <FiCalendar size={25} /> <p>Agendar</p>
              </ScheduleInit>
            </InitCall>
          </RowMain>
        </Main>

        <ReactTooltip />

        <Patient
          isOpen={modalPatient}
          onClose={() => {
            openModalPatient();
          }}
        />
        <Profile isOpen={modalUser} onClose={openModalUser} />

        <VerificationTel
          isOpen={modalVerification}
          onClose={() => {
            openModalVerification();
          }}
        />
        <VerificationPin
          isOpen={modalPin}
          onClose={() => {
            openModalPin();
          }}
        />
      </Container>
    </SelectPatientBackgroundColor>
  );
}
